<template>
  <!-- <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="especial-comments-form-container">
            <v-layout row class="comments-header">
              <v-btn
                class="close-comments-modal"
                icon
                dark
                @click="$store.commit('showModal', false);"
              >
                <v-icon medium>close</v-icon>
              </v-btn>
              <v-flex class="comments-header-title" style="padding-left: 5vw">
                <span class="comments-title text-uppercase title-font">deja tu comentario</span>
              </v-flex>
            </v-layout>
            <slot class="form"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>-->
  <v-dialog lazy v-model="open" content-class="v-dialog-noshadow">
    <v-layout row>
      <v-spacer></v-spacer>
      <v-flex xs12 sm6>
        <slot />
      </v-flex>
      <v-flex class="ml-4">
        <v-btn icon dark @click.stop="open = false" class="ma-0">
          <v-icon medium>close</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  name: "ComentariosModal",
  computed: {
    open: {
      get() {
        return this.$store.getters.showModal;
      },
      set(value) {
        this.$store.commit("showModal", value);
      }
    }
  }
};
</script>

<style scoped>
/* @media only screen and (min-device-width: 0px) and (max-device-width: 320px) {
        .modal-container {
            overflow: hidden;
            overflow-y: scroll;
            margin-top: 23vh!important;
        }
    }

    @media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
        .modal-container {
            overflow: hidden;
            overflow-y: scroll;
            margin-top: 23vh;
        }
    }

    @media only screen and (device-width: 360px) and (device-height: 740px) {
        .modal-container {
            overflow: hidden;
            overflow-y: scroll;
            margin-top: 2vh!important;
        }
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
        .especial-comments-form-container {
            width: 60vw;
            position: relative;
            left: 20vw;
            top: 1vw;
            height: 35vw;
        }

        .modal-mask {
            top: 64px;
            width: auto;
        }

        .modal-container {
            height: 100vh;
        }

        .comments-header {
            position: relative;
            right: 10vw;
        }

        .comments-header-title {
            max-width: 30vw;
            position: relative;
            right: 0;
            left: 14.2vw;
        }

        .close-comments-modal {
            position: relative;
            left: 10vw;
        }
    } */
</style>