<template>
  <carousel-3d
    clickable
    width="200"
    height="200"
    :space="space"
    controlsVisible
    controlsPrevHtml="<i class='v-icon material-icons theme--dark'>chevron_left</i>"
    controlsNextHtml="<i class='v-icon material-icons theme--dark'>chevron_right</i>"
  >
    <slide v-for="(slide, i) in slides" :index="i" :key="i">
      <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
        <v-img
          :data-index="index"
          :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }"
          :src="slide.img"
          width="200"
          height="200"
        >
          <a :href="slide.href" target="_blank">
            <v-layout
              column
              justify-center
              class="px-4 slide-text-container text-uppercase title-font slide-title white--text"
            >
              <span>{{slide.titulo_top}}</span>
              <span>{{slide.titulo_bottom}}</span>
            </v-layout>
          </a>
        </v-img>
      </template>
    </slide>
  </carousel-3d>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: "OtrasHistoriasCarousel3d",
  props: {
    space: {
      type: Number,
      default: 170
    }
  },
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      slides: [
        {
          titulo_top: "Michel Boutic",
          titulo_bottom: "un promotor de reggaetón",
          img:
            "https://eltoque.com/wp-content/uploads/2019/05/michel_boutic-1.png",
          href: "https://eltoque.com/michel-boutic-un-promotor-de-reggaeton/"
        },
        {
          titulo_top: "Qué ha sido lo mas pegao",
          titulo_bottom: "del reguetón cubano",
          img:
            "https://eltoque.com/wp-content/uploads/2018/06/cuba-reparto-6.jpg",
          href:
            "https://eltoque.com/que-ha-sido-lo-mas-pegao-del-regueton-cubano/"
        },
        {
          titulo_top: "Cuatro cosas que todos deberíamos aprender",
          titulo_bottom: "del reguetón y de los reguetoneros",
          img:
            "https://eltoque.com/wp-content/uploads/2018/12/reguetoneros.jpg",
          href:
            "https://eltoque.com/cuatro-cosas-que-todos-deberiamos-aprender-del-regueton-y-de-los-reguetoneros/"
        },
        {
          titulo_top: "Antonella",
          titulo_bottom: "repartera cubana",
          img:
            "https://eltoque.com/wp-content/uploads/2018/11/Antonela_Reparto_Musica_Cuba_2018_elToque.jpg",
          href: "https://eltoque.com/antonella-repartera-cubana/"
        },
        {
          titulo_top: "Reparto, reguetón,",
          titulo_bottom: "cubanos pobres",
          img:
            "https://eltoque.com/wp-content/uploads/2018/06/cuba-reparto-6.jpg",
          href: "https://eltoque.com/reparto-regueton-cubanos-pobres/"
        },
        {
          titulo_top: "Emisora de música “repartera”",
          titulo_bottom: "transmite desde Nueva York y Lawton",
          img:
            "https://eltoque.com/wp-content/uploads/2019/08/Radio-Ragga-Morffa-Foto-cortes--a-del-entrevistado.jpg",
          href:
            "https://eltoque.com/emisora-de-musica-repartera-transmite-desde-nueva-york-y-lawton/"
        },
        {
          titulo_top: "ASIEL BABASTRO:",
          titulo_bottom: "EL REGUETÓN ES EL SOUNDTRACK DE CUBA",
          img:
            "https://eltoque.com/wp-content/uploads/2019/07/asiel-babastro.png",
          href:
            "https://eltoque.com/asiel-babastro-el-regueton-es-el-soundtrack-de-cuba/ "
        }
      ]
    };
  }
};
</script>

<style>
/* .carousel-3d-oh {
  height: auto;
} */

.carousel-3d-controls > .prev, .carousel-3d-controls > .next {
    color: white;
    background-color: black;
    border: solid;
}
.carousel-3d-controls > .prev {
    left: 20%;
}
.carousel-3d-controls > .next {
    right: 20%;
}
.prev .v-icon, .next .v-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 50px;
}

.slide-text-container {
  /* padding: 0 20px; */
  background-color: rgba(0, 0, 0, 0.65);
  /* height: 200px; */
  height: 100%;
}

.slide-title {
  /* color: white; */
  letter-spacing: 3px;
}
</style>
