<template>
  <v-flex shrink class="canciones-actions-sidebar">
    <!-- <div class="canciones-home-actions-wrapper"> -->
    <v-layout
      id="actions"
      v-if="view_actions"
      column
      class="canciones-home-actions-i lista-canciones-actions-i"
      :class="color"
    >
      <div :style="{backgroundColor: color_share}" class="ma-auto" :class="{'button-dimensions': view_juego}">
        <e-social-sharing
          top
          right
          direction="left"
          transition="slide-x-transition"
          share_canciones
          share_icon_color="white"
        />
      </div>
      <v-spacer class="sidebar-spacer" :class="{'juego-sidebar': view_juego}"></v-spacer>
      <div
        :style="{backgroundColor: color_comment}"
        :class="{'button-dimensions': view_juego}"
        class="ma-auto"
      >
        <v-btn dark icon flat class="show-modal-btn" @click="$store.commit('showModal', true)">
          <v-icon medium>comment</v-icon>
        </v-btn>
      </div>
    </v-layout>

    <div class="canciones-filters" v-if="view_filters">
      <div v-for="(item, n) in filtros" :key="n">
        <v-tooltip left dark close-delay=".5s" :color="item.color" :disabled="disabled_tooltip">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="filter-btn ma-0 pa-0"
              :class="{'filter-small': small}"
              :color="item.color"
              @click="onFilter($event, item.name, item.color)"
            ></v-btn>
          </template>
          <span class="text-font">{{ item.name }}</span>
        </v-tooltip>
      </div>
    </div>
    <!-- </div> -->
  </v-flex>
</template>

<script>
import ESocialSharing from "../SocialSharing/SocialSharing";

export default {
  name: "CancionesSidebar",
  props: {
    view_filters: Boolean,
    view_actions: Boolean,
    color_comment: String,
    color_share: String,
    view_juego: Boolean,
    disabled_tooltip: Boolean,
    color: String,
    small: Boolean,
  },
  components: {
    ESocialSharing
  },
  data() {
    return {
      social_sharing: false,
      filtros: [
        {
          name: "Polémica",
          icon: "",
          color: "#0de0c1"
        },
        {
          name: "Baile",
          icon: "",
          color: "#f600c1"
        },
        {
          name: "Lenguaje",
          icon: "",
          color: "#e5dc16"
        },
        {
          name: "Música",
          icon: "",
          color: "#ee0a1b"
        },
        {
          name: "Social",
          icon: "",
          color: "#7d0ef2"
        }
      ]
    };
  },
  mounted() {
    this.$store.commit("setShowFilteredData", false);
    this.$store.commit("clearFilteredData");
    this.$store.commit("clearFilterList");
  },
  //   computed: {
  //     setOpacity: {
  //       get() {
  //         return this.set_opacity;
  //       },
  //       set(value) {
  //         this.set_opacity = value;
  //       }
  //     }
  //   },
  methods: {
    onFilter(event, item, color) {
      if (!event.target.classList.contains("filter-opacity")) {
        this.filterDataByFact(event, item, color);
      } else {
        event.target.classList.remove("filter-opacity");
        this.backToLista(item);
      }
    },
    filterDataByFact(event, item, color) {
      this.$store.commit("setShowFilteredData", true);
      switch (item) {
        case "Música":
          this.getFilterData(event.target, "Música", color);
          break;
        case "Baile":
          this.getFilterData(event.target, "Baile", color);
          break;
        case "Polémica":
          this.getFilterData(event.target, "Polémica", color);
          break;
        case "Social":
          this.getFilterData(event.target, "Social", color);
          break;
        case "Lenguaje":
          this.getFilterData(event.target, "Lenguaje", color);
          break;
        default:
          break;
      }
    },
    getFilterData(target, item, color) {
      let titulos_principales = this.$store.getters.titulosPrincipales,
        result = [];
      titulos_principales.filter(function(el) {
        for (let fact of el.data.fact) {
          if (fact === item) {
            el.show_info = true;
            result.push(el);
          }
        }
      });
      if (result.length >= 0) {
        target.classList.add("filter-opacity");
        if (result.length == 0) {
          this.$store.commit("addFilteredData", { fact: item, response: "" });
          this.$store.commit("addFilter", {
            fact: item,
            color: color,
            no_data: true,
            msg: "En este año no hay canciones en esta categoría"
          });
        } else {
          this.$store.commit("addFilteredData", {
            fact: item,
            response: result
          });
          if (!this.searchFilter(item)) {
            this.$store.commit("addFilter", { fact: item, color: color });
          }
        }
      }
    },
    searchFilter(el) {
      this.$store.getters.getFilterList.filter(function(val) {
        if (el.fact === val) return true;
      });
    },
    backToLista(item) {
      if (this.$store.getters.getFilteredData.length == 1) {
        this.$store.commit("setShowFilteredData", false);
        this.closeFilterInfoBox();
        this.$store.commit("clearFilteredData");
        this.$store.commit("clearFilterList");
      } else {
        this.removeFilterData(item);
        this.removeFilterName(item);
      }
    },
    removeFilterData(item) {
      let c = 0;
      for (let el of this.$store.getters.getFilteredData) {
        if (el.fact === item) {
          this.closeFilterInfoBox(el);
          this.$store.getters.getFilteredData.splice(c, 1);
        }
        c++;
      }
    },
    removeFilterName(item) {
      let c = 0;
      for (let el of this.$store.getters.getFilterList) {
        if (el.fact === item) {
          this.$store.getters.getFilterList.splice(c, 1);
        }
        c++;
      }
    },
    closeFilterInfoBox(item) {
      //   console.log(item)
      if (item !== undefined) {
        if (item.response.filter !== undefined) {
          item.response.filter(val => {
            if (!val.hasOwnProperty("bestSong")) {
              val.show_info = false;
            }
          });
        }
      } else {
        for (let data of this.$store.getters.getFilteredData) {
          if (data.response.filter !== undefined) {
            data.response.filter(val => {
              if (!val.hasOwnProperty("bestSong")) {
                val.show_info = false;
              }
            });
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.filter-btn {
  border-radius: 0;
  width: 80px;
  height: 80px;
  opacity: 0.7;
}

.filter-btn.filter-small {
  height: 60px;
  min-width: unset;
  width: 100%;
}

#actions {
  /* position: fixed; */
}

/* .show-modal-btn {
        margin-left: -2.5vw;
        margin-top: -1vh;
        margin-bottom: -1vh;
    } */
/*
.share-canciones-btn {
  margin-left: -2.5vw;
}

.share-facebook {
  color: #f7d730;
  margin-left: 0.5vw;
  font-size: 6vw;
}

.canciones-home-actions {
  background-color: rgba(0, 0, 0, 0.91);
  margin-top: -1vh;
} */

/* .canciones-home-actions-i {
        padding-top: 14vh;
    } */

/* .lista-canciones-actions-i {
  padding-top: 5vw;
  padding-bottom: 5vw;
  margin: 0 5vw;
} */

/* .canciones-actions-sidebar{
        max-width: 15vw;
        position: fixed;
        top: 9vh;
        right: 0;
    } */

/* .filter-1, .filter-2,.filter-3,
    .filter-4,.filter-5, .filter-6 {
        margin: 0!important;
        min-width: 15vw;
        padding: 0!important;
        border-radius: 0!important;
        opacity: 0.7;
    } */

.filter-opacity {
  opacity: 1;
}

/* .set-opacity {
  opacity: 0.7;
} */

/* @media only screen and (min-width: 0px) and (max-width: 740px) {
  .button-dimensions {
    width: 36px;
    height: 35px;
  }

  .sidebar-spacer {
    margin-bottom: 3vh;
  }

  .juego-sidebar {
    margin: 0;
  }
} */

/* @media only screen and (min-width: 360px) and (max-width: 740px) {
        .canciones-actions-sidebar{
            top: 11vh;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 320px) {
        .canciones-actions-sidebar{
            top: 10.5vh;
        }
    }

    @media only screen and (width: 360px) and (height: 740px) {
        .canciones-actions-sidebar{
            top: 9vh;
        }
    } */

/* @media (min-width: 400px) and (max-width: 740px) {
  .canciones-actions-sidebar {
            width: 8vw;
        }

  .canciones-home-actions-i {
    margin: 0;
  }
} */

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .canciones-actions-sidebar{
            position: relative;
            right: 2vw;
            top: 0;
            max-height: 50vh;
        }

  .filter-1, .filter-2,.filter-3,
        .filter-4,.filter-5, .filter-6 {
            min-width: 7vw;
            height: 15vh;
        }
} */
</style>
