<template>
  <v-layout :column="!footer" class="black">
    <v-layout row wrap>
      <v-spacer v-if="!footer"></v-spacer>
      <v-flex :sm12="footer" :md4="footer" xs12 :sm6="!footer" :md5="!footer" v-if="$store.getters.getViewCancionesBox">
        <HomeContentBox
          top="canciones"
          bottom="+ populares"
          img="/img/home/canciones-box.jpg"
          to="/canciones-populares"
          :class="{'footer': footer}"
        >
          A continuación, recogemos de manera sintetizada los éxitos,
          controversias y momentos más significativos de este género urbano en
          Cuba en los últimos ocho años.
        </HomeContentBox>
      </v-flex>
      <v-flex :sm12="footer" :md4="footer" xs12 :sm6="!footer" :md5="!footer" v-if="$store.getters.getViewArtistasBox">
        <HomeContentBox
          top="la realeza"
          to="/la-realeza"
          img="/img/home/artistas-box.jpg"
          :class="{'footer': footer}"
        >
          ¿Quiénes son los más populares? o ¿quiénes han estado más tiempo
          pegados? son preguntas habituales cuando se habla de reguetón cubano.
          A partir de una lista de 155 artistas y un análisis de varios
          indicadores empezamos a calcular... Aquí los resultados... y un ganador.
        </HomeContentBox>
      </v-flex>
      <v-flex :sm12="footer" :md4="footer" xs12 :sm6="!footer" :md5="!footer" v-if="$store.getters.getViewLasBalasBox">
        <HomeContentBox
          top="las balas"
          to="/las-balas"
          img="/img/home/money-box.jpg"
          :class="{'footer': footer}"
        >
          El reguetón, al que muchos creyeron pasajero, con letras poco
          convencionales y estética particular generó una industria con
          artistas, productores musicales, realizadores de videos,
          distribuidores, canales para su consumo y circuitos de presentación. Y
          no solo en Cuba, también traspasó la frontera nacional. En la actualidad,
          parece consolidada y mueve millones de dólares anualmente.
        </HomeContentBox>
      </v-flex>
      <v-flex :sm12="footer" :md4="footer" xs12 :sm6="!footer" :md5="!footer" v-if="$store.getters.getViewJuegoBox">
        <HomeContentBox
          top="el cubetón"
          to="/el-cubeton"
          img="/img/home/juego-box.jpg"
          :class="{'footer': footer}"
        >
          Para que juegues a ritmo de reguetón cubano.
          <br />
          La Cloaca Games es el nombre bajo el cual un grupo de jóvenes
          de diferentes formaciones se unieron motivados por desarrollar un
          video juego sobre reguetón para cubanas y cubanos. El Cubetón, todavía
          en su versión de prueba, recopila canciones de reguetón y te propone
          un reto con ellas. Te adelantamos que no está fácil, pero descárgale.
        </HomeContentBox>
      </v-flex>
      <v-spacer v-if="!footer"></v-spacer>
    </v-layout>
  </v-layout>
</template>

<script>
import HomeContentBox from "./HomeContentBox";

export default {
  name: "Components",
  components: {
    HomeContentBox
  },
  props: {
    footer: Boolean
  }
};
</script>

<style>
/* @media only screen and (min-device-width: 0) and (max-device-width: 320px) {
  .home-components-container {
    margin-top: -1vh;
  }
}

@media only screen and (min-device-width: 0) and (max-device-width: 740px) {
  .component-box,
  .bottom-components-box,
  .top-components-box {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .home-components-container {
    position: relative;
    top: 6px;
  }

  .row-layout {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    max-width: 100vw;
  }

  .cmp-box-width#box-content-container {
    width: auto;
  }
  .cmp-box-width .e-component-box {
    width: 33vw;
  }

  .row-layout .top-components-box {
    left: 0;
  }

  .row-layout .bottom-components-box {
    top: 0;
  }

  .component-box {
    position: relative;
    width: 90vw;
  }

  .bottom-components-box {
    top: 11px;
  }

  .top-components-box {
    left: 10vw;
  }
} */
</style>
