<template>
  <v-speed-dial :direction="direction" :transition="transition">
    <template v-slot:activator>
      <v-btn icon @click="show_social_sharing = !show_social_sharing; updateUrl();">
        <v-icon :color="share_icon_color" class="share-icon">share</v-icon>
      </v-btn>
    </template>

    <social-sharing
      :url="url"
      :title="title"
      :description="description"
      hashtags="ReggaetonCubano"
      inline-template
    >
      <div>
        <network network="twitter" popup>
          <v-btn fab dark small color="white">
            <font-awesome-icon :icon="['fab', 'twitter']" color="#1c75b7" />
          </v-btn>
        </network>
        <network network="facebook" popup>
          <v-btn fab dark small color="indigo">
            <font-awesome-icon :icon="['fab', 'facebook-f']" />
          </v-btn>
        </network>
        <network network="whatsapp" popup>
          <v-btn fab dark small color="#25D366">
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
          </v-btn>
        </network>
        <network network="telegram" popup>
          <v-btn fab dark small color="#0088cc">
            <font-awesome-icon :icon="['fab', 'telegram-plane']" />
          </v-btn>
        </network>
      </div>
    </social-sharing>
  </v-speed-dial>
</template>

<script>
//   import JuegoSidebar from '../../components/Juego/JuegoSidebar'
export default {
  name: "ESocialSharing",
  props: {
    transition: String,
    direction: String,
    // share_canciones: Boolean,
    share_icon_color: {
      type: String,
      default: "#f7d730"
    }
  },
  data() {
    return {
      show_social_sharing: false,
      title: "",
      description: "",
      url: "",
    };
  },
  methods: {
    updateUrl() {
      if (!this.show_social_sharing) return;
      this.url = window.location.origin + this.$route.fullPath;

      const meta = this.$route.meta;
      if (!meta) return;
      this.title = meta.title;
      this.description = meta.description;
    }
  },
};
</script>

<style scoped>
/* .icon-share-container {
  padding: 4vw 4vw 2vw;
}

.social-sharing-mobile {
  margin: 0;
}

.icon-share-container .share-icon {
  left: 12px;
  top: -14px;
  position: relative;
}

.social-sharing {
  margin-right: 9vw;
}

.btn-social-sharing {
  margin-top: 5vh;
  margin-left: -3vw;
}

.canciones-share .btn-social-sharing {
  margin-left: 2vw;
}

.canciones-share {
  margin-top: -7vh;
  margin-left: 2.8vh;
}

.twitter-btn {
  color: #1c75b7;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1903px) {
  .btn-social-sharing {
    margin-left: 0 !important;
  }

  .share-icon {
    font-size: 21px !important;
  }

  .social-sharing {
    margin-right: 26px;
    margin-left: 0vw;
  }
} */
</style>
