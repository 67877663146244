/* eslint-disable no-unused-vars */
import Vue from "vue";

/* plugins */
import "./plugins/vuetify";
import "./plugins/axios";
// import './plugins/fullpage';

import "vuetify/dist/vuetify.min.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fas,
  faBars,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTelegramPlane
} from "@fortawesome/free-brands-svg-icons";

import App from "./App.vue";
import injectInitialState from "./utils/inject-initial-state";
import router from "./router";
import store from "./store";

import VueAnalytics from "vue-analytics";

if (process.env.NODE_ENV === "production") {
  Vue.use(VueAnalytics, {
    id: "UA-54853009-1",
    router
  });
}

// import VueAnimate from 'vue-animate-scroll'
// import VueScrollStop from 'vue-scroll-stop'
// import Vue2TouchEvents from 'vue2-touch-events'
import Vuelidate from "vuelidate";
// import Carousel3d from 'vue-carousel-3d'
// import VueToast from 'vue-toast-notification'
import VueYouTubeEmbed from "vue-youtube-embed";
// import vuescroll from 'vuescroll';
import Vuebar from "vuebar";

// import 'vue-toast-notification/dist/index.css'

import Sticky from "vue-sticky-directive";
Vue.use(Sticky);

import SocialSharing from "vue-social-sharing";
// import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

// Vue.directive('tooltip', VTooltip);
// Vue.directive('close-popover', VClosePopover);
// Vue.component('v-popover', VPopover);

Vue.use(SocialSharing);

// Vue.use(VueAnimate);
// Vue.use(VueScrollStop);
// Vue.use(Vue2TouchEvents);
Vue.use(Vuelidate);
// Vue.use(Carousel3d);
// Vue.use(VueToast);
Vue.use(VueYouTubeEmbed);
// Vue.use(vuescroll);
Vue.use(Vuebar);

// import VueScrollmagic from 'vue-scrollmagic'
// Vue.use(VueScrollmagic, {
//   vertical: true,
//   globalSceneOptions: {},
//   loglevel: 2,
// });

library.add(
  fas,
  faBars,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCircle,
  faStar,
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTelegramPlane,
);

// Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);

import is_mobile from "./mixins";

Vue.mixin(is_mobile);

if ("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}

const app = new Vue({
  router,
  store,
  render: h => h(App)
});

if (process.env.NODE_ENV === "production") {
  if (window.__INITIAL_STATE__) store.replaceState(window.__INITIAL_STATE__)

  router.beforeResolve(async (to, from , next) => {
    try {
      const components = router.getMatchedComponents(to)
      // By using `await` we make sure to wait
      // for the API request made by the `fetch()`
      // method to resolve before rendering the view.
      await Promise.all(components.filter(x => x.fetch).map(x => x.fetch({ store })))
      // The `injectInitialState()` function injects
      // the current state as a global variable
      // `__INITIAL_STATE__` if the page is currently
      // pre-rendered.
      if (window.__PRERENDER_INJECTED) injectInitialState(store.state)
    } catch (error) {
      console.log(error)
    }

    return next();
  });
}

app.$mount("#app");
