<template>
  <div>
    <router-link :to="to">
      <v-hover class="hidden-sm-and-down">
        <div slot-scope="{ hover }">
          <v-img :src="img" height="273">
            <v-layout column justify-center align-center class="overlay">
              <v-expand-transition>
                <span v-if="hover" class="content-desc text-body">
                  <slot></slot>
                </span>
              </v-expand-transition>
              <div v-if="!hover">
                <span v-text="top" class="text-uppercase title-font content-title"></span>
                <v-spacer></v-spacer>
                <span v-text="bottom" class="text-uppercase title-font content-title"></span>
              </div>
            </v-layout>
          </v-img>
        </div>
      </v-hover>

      <v-img class="hidden-md-and-up" :src="img" height="198"></v-img>
    </router-link>

    <v-btn flat class="hidden-md-and-up title-font text-uppercase ma-0 details-btn" @click="toggleExpand()">
      {{top}}
      {{bottom}}
      <span id="expand-indicator">
        <v-icon class="expand-icon" :class="{'open': expand}">keyboard_arrow_down</v-icon>
      </span>
    </v-btn>

    <v-expand-transition>
      <v-layout row align-end v-if="expand" class="white">
      <v-flex class="pa-4 text-body" id="expand-desc">
        <slot></slot>
      </v-flex>
      <v-btn icon color="black" :to="to" id="link-icon">
        <v-icon color="white">add</v-icon>
      </v-btn>
      </v-layout>
    </v-expand-transition>
    <!-- <div class="component-box-info-container">
    <v-card-title class="box-title box-display" style="overflow: visible;">
      <div id="title-text">
        <router-link :to="to" class="route-to-component">
          <h3 class="text-uppercase title-font letter-space">{{ box_type }}</h3>
        </router-link>
      </div>
      <div class="box-icon icon" @click="expandCloseComponent()">
        <font-awesome-icon v-if="open_box" icon="angle-up" />
        <font-awesome-icon v-if="close_box" icon="angle-down" />
      </div>
    </v-card-title>
    <v-layout
      row
      id="box-description-container"
      class="box-description-container"
      ref="boxDescriptionContainer"
      :class="{'display-description':display_description,
                  'hide-description': !display_description,
                  'open-box':open_box, 'close-box':close_box}"
    >
      <div class="flex-description-text description-text">
        <div id="description-text" class="text-font" style="margin-top: 5px">
          <p v-html="text"></p>
        </div>
      </div>
      <v-flex class="flex-description-icon">
        <router-link :to="to" class="go-to-link text-capitalize">
          <v-icon class="see-more-icon">add</v-icon>
        </router-link>
      </v-flex>
    </v-layout>
    </div>-->
  </div>
</template>

<script>
// import { isMobile } from 'mobile-device-detect';
export default {
  name: "HomeContentBox",
  props: {
    to: String,
    top: String,
    bottom: String,
    img: String
  },
  data() {
    return {
      // icon_name: "angle-down",
      // display_description: false,
      // open_box_list: null,
      // close_box: true,
      // open_box: false,
      // is_open_close: "",
      expand: false
    };
  },
  // mounted() {
  //   this.open_box_list = [];
  // },
  // computed: {
  //   displayDescription: {
  //     get() {
  //       return this.display_description;
  //     },
  //     set(value) {
  //       this.display_description = value;
  //     }
  //   }
  // },
  methods: {
    // setFullImage: function(value) {
    //   this.full_image = value;
    // },
    toggleExpand() {
      this.expand = !this.expand;
      // const { boxDescriptionContainer } = this.$refs;
      // let context = this.$el.__vue__,
      //   close_box = document.getElementsByClassName("open-box");
      // if (boxDescriptionContainer.classList.contains("close-box")) {
      //   if (close_box.length > 0) {
      //     this.fn_close(close_box.item(0).parentNode.parentNode.__vue__);
      //   }
      //   this.fn_expand();
      // } else {
      //   this.fn_close(context);
      // }
    }
    // fn_expand: function() {
    //   // this.setFullImage('display-full-image');
    //   this.display_description = true;
    //   this.close_box = false;
    //   this.open_box = true;
    //   // this.$el.scrollIntoView(true);
    // },
    // fn_close: function(el) {
    //   el.$set(el._data, "close_box", true);
    //   el.$set(el._data, "open_box", false);
    //   el.$set(el._data, "display_description", false);
    //   // el._data.display_description = false;
    //   // el._data.close_box = true;
    //   // el._data.open_box = false;
    //   // console.log(el._data)
    // },
    // goTo: function(name) {
    //   this.$router.push({ name: name });
    // }
  }
};
</script>

<style scoped>
#expand-desc {
  font-size: 16px;
  letter-spacing: 1px;
}

.footer .content-desc {
  font-size: 15px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.68);
  height: 100%;
}

.content-title {
  font-size: 25px;
  color: white;
  line-height: 1;
  letter-spacing: 4px;
}

.details-btn {
  color: white;
  font-size: 18px;
  width: 100%;
  border-radius: 0;
  letter-spacing: 2px;
}
.details-btn #expand-indicator {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

#expand-indicator > .v-icon.expand-icon {
  transition: transform ease-in 250ms;
}
.v-icon.expand-icon.open {
  transform: rotateZ(180deg);
}

#link-icon {
  border-radius: unset;
  margin: 0;
}

.content-desc {
  font-size: 17px;
  color: white;
  padding: 0 54px;
  text-align: center;
}
/* .letter-space {
  letter-spacing: 3px;
}

.flex-description-text {
  width: 90vw;
  padding: 24px;
  height: auto;
  letter-spacing: 2px;
  font-size: 16px;
}

.flex-description-icon {
  max-width: 40px;
  height: 40px;
  background-color: black;
  margin-top: auto;
  padding: 5px 4px 0;
  text-align: center;
}

.go-to-link {
  text-decoration: none;
}

.see-more-icon {
  color: white !important;
}

#box-content-container {
  width: 100%;
  text-align: left;
  height: auto;
  background: white;
}

.mb-box-open {
  margin-bottom: -5px !important;
}

.mb-box-close {
  margin-bottom: -11px !important;
}

.box-title {
  background-color: black;
  color: white;
  width: 100%;
  display: inline-block;
  padding: 5px 10px 5px 15px;
  margin-top: -6px;
  z-index: 1 !important;
}

.box-icon {
  float: right;
  margin-top: 2px;
}

#title-text {
  float: left;
}

.box-img {
  width: 100%;
  right: 0;
  top: -6px;
  height: 35vh;
  border-radius: 0 !important;
}

.hide-description {
  display: none;
}

#box-description-container {
  margin-top: -6px;
}

.description-text {
  background-color: white;
}

.display-description {
  display: flex !important;
}

.display-desktop-title {
  display: none;
}

@keyframes full-img {
  from {
    max-height: 35vh;
  }
  to {
    max-height: 100%;
  }
}
.display-full-image {
  animation-name: full-img;
  animation-duration: 1ms;
  animation-iteration-count: infinite;
}

.route-to-component {
  text-decoration: none;
  color: white;
}

.component-box-info-container {
  display: inherit;
}

@media only screen and (min-width: 0px) and (max-width: 740px) {
  .component-box-info-container {
    background-color: white;
  }

  .displayOnDesktop {
    display: none;
  }
}

@media only screen and (min-width: 0px) and (max-width: 320px) {
  .box-img {
    right: 32px;
    top: 2vw;
  }

  .box-title {
    margin-top: 1vh;
  }
}

@media only screen and (min-width: 360px) and (max-width: 400px) {
  .box-canciones {
    margin-top: 40px;
  }

  .box-img {
    right: 35px;
    top: 0;
  }

  .box-title {
    margin-top: 0;
  }

  .mb-box-open {
    margin-bottom: -1px !important;
  }

  .mb-box-close {
    margin-bottom: -5px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 740px) {
  .box-canciones {
    margin-top: 25px;
  }

  .box-img {
    right: 10vw;
    top: -5px;
  }

  .flex-description-text {
    height: 15vw;
  }

  .mb-box-open {
    margin-bottom: 6px !important;
  }

  .mb-box-close {
    margin-bottom: -1px !important;
  }

  #box-description-container {
    margin-top: -6px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 568px) {
  .mb-box-open {
    margin-bottom: 5px !important;
  }

  .mb-box-close {
    margin-bottom: -1px !important;
  }

  #box-description-container {
    margin-top: -5px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .displayOnMobile {
    display: none;
  }

  .text-fontSize {
    font-size: 16px;
  }

  #box-content-container {
    background-color: transparent;
  }

  .component-box-info-container {
    display: none;
  }

  .box-img {
    right: 0;
    height: 20vw;
  }

  .box-display {
    display: none;
  }

  .display-desktop-title {
    display: block;
    text-align: center;
  }

  .img-cover-color {
    background-color: rgba(0, 0, 0, 0.68);
    height: 20vw;
    padding-top: 9vw;
  }

  .desktop-box-title {
    font-family: AkzidenzGrotesk-ExtraBoldCond, Andada-Bold, Roboto, sans-serif;
    color: white;
    font-size: 4vh;
  }

  .desktop-title-spacer {
    margin-top: -2vh;
  }

  .e-cpm-text-reveal {
    font-size: 18px;
    text-align: center;
    align-items: center;
    font-family: AkzidenzGrotesk-Light, Roboto, sans-serif;
    padding: 0 4vw 0;
  }
} */
</style>
