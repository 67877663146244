<template>
  <v-app id="app" class="application-container">
    <EspecialToolbar :default_gradient="edefault" :money_gradient="money" :juego_gradient="juego" />
    <v-content id="content-container" fluid>
      <!-- <juego/> -->
      <!-- <money/> -->
      <keep-alive>
        <router-view class="home-view"></router-view>
      </keep-alive>
      <v-layout column class="app-footer">
        <AppFooter />
      </v-layout>
    </v-content>
    <ComentariosModal>
      <ComentariosForm></ComentariosForm>
    </ComentariosModal>
  </v-app>
</template>

<script>
import EspecialToolbar from "./components/EspecialToolbar";
// import ListaComentarios from "./components/Comentarios/ListaComentarios";
import ComentariosModal from "./components/Comentarios/ComentariosModal";
import ComentariosForm from "./components/Comentarios/ComentariosForm";
import AppFooter from "./components/Home/AppFooter";

// import JuegoMobile from "./views/Juego/JuegoMobile";

export default {
  name: "App",
  components: {
    EspecialToolbar,
    // ListaComentarios,
    ComentariosModal,
    ComentariosForm,
    AppFooter
    // juego: JuegoMobile
  },
  data() {
    return {
      edefault: false,
      money: false,
      juego: false
    };
  },
  mounted() {
    this.setToolbarGradient();
    // window.addEventListener("scroll", this.scrollDown);
  },
  beforeUpdate() {
    this.setToolbarGradient();
  },
  methods: {
    setToolbarGradient() {
      this.initPropsData();
      let current_route = this.$router.currentRoute;
      if (
        current_route.name === "component.home" ||
        current_route.name === "la.realeza"
      ) {
        this.edefault = true;
      } else if (current_route.name === "las.balas") this.money = true;
      else if (current_route.name === "el.cubeton") this.juego = true;
      //   else {
      //     this.initPropsData();
      //   }
    },
    initPropsData() {
      this.edefault = false;
      this.money = false;
      this.juego = false;
    }
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Andada";
  font-weight: bold;
  font-display: auto;
  src: url("/css/fonts/Andada/Andada-Bold.otf");
}

@font-face {
  font-family: "Andada";
  font-weight: 400;
  font-display: auto;
  src: url("/css/fonts/Andada/Andada-Regular.otf");
}

@font-face {
  font-family: "Andada";
  font-style: italic;
  font-display: auto;
  src: url("/css/fonts/Andada/Andada-Italic.otf");
}

@font-face {
  font-family: "Segoe";
  font-weight: 400;
  font-display: auto;
  src: url("/css/fonts/Segoe/segoeui.ttf");
}

@font-face {
  font-family: "Segoe";
  font-weight: bold;
  font-display: auto;
  src: url("/css/fonts/Segoe/segoeuib.ttf");
}

@font-face {
  font-family: "Segoe";
  font-weight: 300;
  font-display: auto;
  src: url("/css/fonts/Segoe/segoeuil.ttf");
}

video.main-video {
  width: 100%;
}

/* .canciones-share .v-speed-dial__list {
  margin-top: 2vh !important;
} */

#content-container {
  background-color: #eae7e7;
  /* padding: 0!important; */
  /* margin: 0; */
}

/* .app-footer {
  margin-top: 5vw;
}

.comentarios {
  margin-left: 6vw;
} */

.text-body {
  font-family: "Segoe";
  font-weight: 400;
}

@media only screen and (min-width: 600px) {
  .especial-menu .v-list__tile {
    height: unset;
  }
}

.v-overlay--active:before {
  opacity: 0.9;
}

.title-font {
  font-family: "Andada";
  font-weight: bold;
}

.text-font {
  font-family: "Andada";
  font-weight: 400;
}

.title-space {
  margin-right: 5px;
}

.title-text {
  letter-spacing: 3px;
}

.graficos-text-box {
  background-color: black;
  color: white;
  width: 87vw;
}

.top-artistas-background {
  background-color: gold;
}

.artista-photo {
  margin: 2px 8px;
  flex: 0 0 auto;
  border-radius: 100%;
  padding: 2px;
}

.v-dialog-noshadow {
  box-shadow: none;
}

.comments-header-title {
  letter-spacing: 3px;
}

/*
    *   Estilos para el component vue-animate-scroll
    */

/* .animate {
  transition-delay: 0.1s;
  transition-duration: 0.25s;
  transition-timing-function: ease-in;
}

.slide-up {
  transform: translateY(0);
}

.slide-up.animate-active {
  transform: translateY(-40px);
}

.slide-up-enter-active {
  transition: all 0.5s ease;
}
.slide-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-up-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0.5;
} */

/*
     * Fondos para los titulos de las canciones con filtro
     */
/* .polemica-bkg {
  background-color: rgba(13, 224, 193, 0.85);
  color: white;
}

.baile-bkg {
  background-color: rgba(246, 0, 193, 0.85);
  color: white;
}

.lenguaje-bkg {
  background-color: rgba(229, 220, 22, 0.85);
  color: black !important;
}

.musica-bkg {
  background-color: rgba(238, 10, 27, 0.85);
  color: white;
}

.social-bkg {
  background-color: rgba(125, 14, 242, 0.85);
  color: white;
}

.non-filter {
  background: rgba(5, 5, 5, 0.7);
  color: white;
}

.comments-header-spacer {
  width: 6vw;
}

.comments-header-title {
  background-color: black;
  right: 0;
  padding: 2vh 0 1vh 7vw;
}

.comments-title {
  letter-spacing: 2vw;
  font-size: 3.8vw;
  color: white;
}

.especial-comments-form-container .comments-form .comments-actions-container {
  top: -84px;
  position: relative;
  z-index: 1;
  right: 0;
  left: 0;
  margin: 0;
}

.e-cmp-camino-toolbar .v-toolbar__content {
  height: 90px !important;
  padding: 0 !important;
} */

/*
    *   Modal styles
    */

/* .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 100vw;
  max-height: 100vh;
  margin: 0 auto;
  padding: 20px 0;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
} */

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */
/*
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.v-messages__wrapper {
  color: rgba(238, 10, 27, 1);
  font-weight: bold;
  font-size: 4vw;
  font-family: AkzidenzGrotesk-ExtraBoldCond, Roboto, sans-serif;
  letter-spacing: 2px;
}

.prev {
  left: 24vw !important;
}

.prev,
.next {
  height: 6vh !important;
  width: 3vw !important;
  top: 2vh !important;
  border: solid white !important;
  line-height: 2vh !important;
}

.next {
  right: 24vw !important;
}

.prev span,
.next span {
  color: white !important;
  font-size: 7vh !important;
  margin-bottom: 0.5vh;
}

.prev span {
  margin-left: 0.7vw;
}

.next span {
  margin-right: 0.7vw;
}

.canciones-bkg {
  max-height: 85%;
  width: 85vw;
} */

/*
    * Canciones styles
    */

/* .add-spacer {
  display: inherit;
  margin-top: -1.5vh;
}

.header-font {
  font-family: "AkzidenzGrotesk-BoldCond", "Roboto Slaf", serif;
  letter-spacing: 2px;
}

.year-title {
  font-size: 33vw;
  font-family: "AkzidenzGrotesk-ExtraBoldCond", serif;
}

.mobile-footer .videos-container {
  padding-bottom: 10vw;
}

.position-sidebar {
  left: 85vw;
}

.sidebar-transparent .canciones-home-actions {
  background-color: transparent !important;
}

.set-img-size .v-image__image {
  background-position-x: -10vw !important;
  background-position-y: center !important;
}

.money-info-presentation {
  background-size: cover;
  width: 100vw;
  position: fixed;
}

@media only screen and (min-device-width: 0) and (max-device-width: 740px) {
  .comentarios {
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: column;
    margin: 0;
  }
}

@media only screen and (max-device-width: 1903px) {
  .prev,
  .next {
    line-height: 3vh !important;
    height: 6vh !important;
    width: 3vw !important;
  }

  .prev {
    left: 24vw !important;
  }

  .next {
    right: 24vw !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .menu-bkg .v-image__image {
    background-image: none !important;
  }

  .comments-title {
    letter-spacing: 4px;
    font-size: 2.2vh;
  }

  .comments-header-spacer {
    display: none;
  }

  .v-messages__wrapper {
    font-size: 2vh;
  }

  .juego-footer .videos-container {
    margin-top: 6vw !important;
  }

  .juego-footer .otras-historias-container {
    right: 0;
  }

  .comentarios {
    margin-top: 6vw;
  }
} */

.vb-content {
  overflow-y: auto !important;
}

/* @media only screen and (min-width: 1024) { */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* } */
</style>
