<template>
  <v-menu
    v-model="especial_menu"
    content-class="especial-menu"
    transition="slide-y-transition"
    max-width="100%"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>{{ menu_icon }}</v-icon>
      </v-btn>
    </template>

    <v-layout column fill-height class="menu-content">
      <v-list class="menu-list title-font">
        <v-list-tile
          v-for="(item, i) in items"
          :key="item.title+'-'+i"
          :to="item.route"
          class="title-font text-uppercase menu-link"
          active-class
        >
          <v-list-tile-content>
            <v-list-tile-title class="menu-link-name">{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon color="white">chevron_right</v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <v-img src="/img/el-toque-logo-movil.png" class="hidden-sm-and-up" id="logo" width="160" max-height="36">
        <a class="eltoque-menu-link" href="https://eltoque.com"></a>
      </v-img>
    </v-layout>
  </v-menu>
</template>

<script>
export default {
  name: "TheEspecialToolbarMenu",
  data() {
    return {
      items: [
        { title: "Inicio", route: "/" },
        { title: "Canciones", route: "/canciones-populares" },
        { title: "La Realeza", route: "/la-realeza" },
        { title: "Las Balas", route: "/las-balas" },
        { title: "El Cubetón", route: "/el-cubeton" }
      ],
      menu_icon: "more_vert",
      especial_menu: false
    };
  },
  beforeUpdate() {
    if (this.especial_menu) this.menu_icon = "close";
    else this.menu_icon = "more_vert";
  },
  methods: {}
};
</script>

<style scoped>
.especial-menu {
  left: 0 !important;
  top: 64px !important;
  right: 0;
  bottom: 0;
  border-radius: 0;
  background: url("/img/menu/menu-bkg.jpeg") no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 600px) {
  .especial-menu {
    bottom: unset;
    left: unset!important;
    background: none;
  }

  div.menu-link-name {
    font-size: 14px;
    line-height: 1;
    height: 14px;
  }
}

.menu-content {
  background-color: rgba(0, 0, 0, 0.8);
}

.v-list.menu-list {
  background: none;
}

.menu-link {
  color: white;
  margin-right: 12px;
  padding: 6px 0;
}

.menu-link-name {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
}

#logo {
  margin: 32px 0 0 16px;
}
/* .especial-menu {
  top: 64px !important;
  border-radius: unset;
}

.especial-menu-content {
  height: inherit;
  width: inherit;
}

.especial-menu-list {
  background-color: transparent !important;
}

.e-menu-items-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
}

.e-menu-item-route {
  text-decoration: none;
  color: white;
  letter-spacing: 3px;
}

.e-menu-route-name {
  margin-right: 12px;
}

.eltoque-menu-logo {
  width: 75vw;
  margin-top: 8vw;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 740px) {
  .eltoque-menu-logo {
    width: 40vw;
    margin-left: 4vw;
  }

  .especial-menu {
    top: 64px !important;
    height: 100vh;
    min-width: 100vw !important;
    background: url("/img/menu/menu-bkg.jpeg") no-repeat;
    background-size: contain;
  }

  .especial-menu-items {
    height: 15vw;
  }

  .e-menu-item-route {
    font-size: 5vw;
  }

  .especial-menu-content {
    padding-top: 5vw;
  }

  .e-menu-spacer {
    flex-grow: 0.95 !important;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 411px) {
  .especial-menu {
    background-size: cover;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1903px) {
  .especial-menu {
    background: none;
    height: 225px;
    right: 0;
    left: auto !important;
    width: 12vw;
  }

  .e-menu-item-route {
    font-size: 10px;
  }

  .especial-menu-items {
    height: 2.5vw;
  }

  .eltoque-menu-logo {
    display: none;
  }
}

@media only screen and (min-device-width: 1904px) and (max-device-width: 2048px) {
} */
</style>
