<template>
  <v-toolbar app fixed dark prominent clipped-right :class="gradient" style="z-index: 11;">
    <a class="hidden-sm-and-down" target="_blank" rel="noreferrer" href="https://eltoque.com">
      <v-img src="/img/logo.png" class="eltoque-toolbar-logo" width="82"></v-img>
    </a>

    <v-spacer class="logo-spacer"></v-spacer>

    <router-link to="/">
      <v-slide-y-reverse-transition>
        <v-toolbar-title
          class="white--text e-toolbar-title"
          v-if="$store.getters.getShowToolbarTitle"
        >
          <v-layout row wrap>
            <v-flex class="title-font text-uppercase">flow y poder: &nbsp;</v-flex>
            <v-spacer></v-spacer>
            <v-flex class="title-font text-uppercase">el regueton en cuba</v-flex>
          </v-layout>
        </v-toolbar-title>
      </v-slide-y-reverse-transition>
    </router-link>

    <v-spacer></v-spacer>

    <e-social-sharing
      class="hidden-xs-only"
      direction="bottom"
      transition="slide-y-transition"
      share_canciones
      share_icon_color="white"
    ></e-social-sharing>
    <v-btn icon class="hidden-xs-only" @click="$store.commit('showModal', true)">
      <v-icon class="e-toolbar-icon">comment</v-icon>
    </v-btn>
    <TheEspecialToolbarMenu />
  </v-toolbar>
</template>

<script>
import TheEspecialToolbarMenu from "./TheEspecialToolbarMenu";
import ESocialSharing from "../components/SocialSharing/SocialSharing";

export default {
  name: "EspecialToolbar",
  components: {
    TheEspecialToolbarMenu,
    ESocialSharing
  },
  props: {
    default_gradient: Boolean,
    money_gradient: Boolean,
    juego_gradient: Boolean
  },
  computed: {
    gradient() {
      if (this.default_gradient) return "default-gradient";
      else if (this.money_gradient) return "money-gradient";
      else if (this.juego_gradient) return "juego-gradient";
      else return "";
    }
  }
};
</script>

<style scoped>
.e-toolbar-title {
  line-height: 1;
  font-size: 14px;
  letter-spacing: 3px;
}
/* .especial-toolbar {
  z-index: 11;
} */
/* .especial-toolbar-title {
} */

/* .e-social-icon {
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
  top: 0;
  left: 1px;
} */

/* .e-social-icon .btn-social-sharing {
  margin-left: 0 !important;
} */

/* .e-toolbar-icon {
  font-size: 21px;
} */

.default-gradient {
  background: linear-gradient(90deg, #f62c85, #8b3efe);
}

.juego-gradient {
  background: url("/img/juego/juego-header-bkg.png") no-repeat !important;
  background-size: cover !important;
}

/* @media only screen and (min-device-width: 0px) and (max-device-width: 740px) {
  .e-toolbar-actions,
  .eltoque-toolbar-link,
  .logo-spacer {
    display: none;
  }

} */

.money-gradient {
  background: linear-gradient(90deg, #ed65ed, #7b8cf4, #f2defa);
  /* background: url("/img/money/mobile/degradado-menu.jpg") no-repeat !important; */
  /* background-size: cover !important; */
}

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 1903px) {
  .especial-toolbar-title {
    font-size: 1vw;
    line-height: 0;
    letter-spacing: 5px;
  }

  .title-spacer {
    display: inline-flex;
    width: 5px;
  }

  .eltoque-toolbar-logo {
    width: 6vw;
  }
} */
</style>
