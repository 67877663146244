<template>
  <form class="comments-form">
    <v-text-field
      class="input-field"
      label="nombre"
      solo
      v-model="name"
      :error-messages="nameErrors"
      @input="$v.name.$touch()"
      @blur="$v.name.$touch()"
      color="black"
    ></v-text-field>

    <v-text-field
      class="input-field"
      label="correo"
      required
      solo
      v-model="email"
      :error-messages="emailErrors"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
      color="black"
    ></v-text-field>

    <v-textarea
      class="input-field text-area-field"
      solo
      placeholder="escribe tu comentario"
      name="text-comment"
      clearable
      no-resize
      value
      v-model="comment"
      :error-messages="commentErrors"
      @input="$v.comment.$touch()"
      @blur="$v.comment.$touch()"
      color="black"
    ></v-textarea>

    <v-layout row>
      <v-spacer></v-spacer>
      <v-btn color="black" icon dark class="ma-0 btn-send-comment" @click="submit">
        <v-icon color="#f7d730">send</v-icon>
      </v-btn>
    </v-layout>
  </form>
</template>

<script>
import {
  email,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "ComentariosForm",
  props: {
    artistas_comments: Boolean
  },
  data() {
    return {
      // previousScroll: 0,
      name: "",
      email: "",
      comment: "",
      category: 10,
      submitStatus: null
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(10)
    },
    email: {
      required,
      email
    },
    comment: {
      required,
      maxLength: maxLength(255)
    }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("El nombre debe ser de hasta 10 caracteres");
      !this.$v.name.minLength &&
        errors.push("El nombre debe ser de mas de 4 caracteres");
      !this.$v.name.required && errors.push("El Nombre es obligatorio");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Introduzca un E-mail valido");
      !this.$v.email.required && errors.push("El E-mail es obligatorio");
      return errors;
    },
    commentErrors() {
      const errors = [];
      if (!this.$v.comment.$dirty) return errors;
      !this.$v.comment.maxLength &&
        errors.push("El texto no debe pasar de 255 caracteres");
      !this.$v.comment.required && errors.push("El comentario es obligatorio");
      return errors;
    }
  },
  methods: {
    // scroll() {
    //   if (window.scrollY > 850) {
    //     window.scrollTo(0, this.previousScroll);
    //   } else this.previousScroll = window.scrollY;
    // },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      axios
        .post("https://rssblog.herokuapp.com/comment", {
          nickname: this.name,
          email: this.email,
          content: this.comment,
          category: this.category
        })
        .then(response => {
          if (response.status == 200) {
            this.clear();
          }
        });
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //     this.submitStatus = 'ERROR';
      // } else {
      //     // do your submit logic here
      //     this.submitStatus = 'PENDING';
      //     setTimeout(() => {
      //         this.submitStatus = 'OK';
      //         this.sendComment();
      //         this.clear();
      //     }, 500)
      // }
    },
    // sendComment() {
    //   // let me = this;
    //   axios
    //     .post("https://rssblog.herokuapp.com/comment", {
    //       nickname: this.name,
    //       email: this.email,
    //       content: this.comment,
    //       category: 10
    //     })
    //     /* .then(response => {
    //       console.log(response.status);
    //       if (response.status === 200) {
    //         console.log(response.request);
    //         me.$toast.open({
    //             message: 'Comentario enviado!'
    //         });
    //       }
    //     }) */;
    // },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.comment = "";
    }
  }
};
</script>

<style scoped>
/* .input-field {
  margin: 0 18vw 0 8vw;
} */

/* .comments-form {
  margin-top: 4vh;
  font-family: AkzidenzGrotesk-Light, Roboto, sans-serif;
} */

.btn-send-comment {
  /* height: 8vh; */
  /* width: 15vw; */
  border-radius: unset;
  /* color: #f7d730; */
  /* padding-left: 2vw; */
}

/* .comments-actions-container {
  margin-top: -3vh;
}

.comments-actions-spacer {
  max-width: 83vw;
} */

/* @media only screen and (min-device-width: 0px) and (max-device-width: 640px) {
  .comments-form {
    margin-left: 4vw;
    margin-right: 8vw;
  }
} */

/* @media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
  .btn-send-comment {
    height: 10vh;
  }
} */

/* @media only screen and (device-width: 360px) and (device-height: 740px) {
  .btn-send-comment {
    height: 8vh;
  }
} */

/* .modal-form {
  width: 85vw;
}

.modal-form-spacer {
  width: 100vw !important;
} */

/* @media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .comments-form {
    max-width: 55vw;
    padding-bottom: 3vh;
  }

  .input-field {
    width: 40vw;
  }

  .comments-actions-container {
    top: 0;
    position: relative;
    z-index: 1;
    right: 0;
    left: 5vw;
  }

  .btn-send-comment {
    height: 8vh;
    width: 5vw;
    padding-left: 0;
  }

  .comments-actions-spacer {
    max-width: 42.5vw;
  }
} */
</style>
