<template>
  <v-layout column class="mt-3">
    <Components v-if="$store.getters.getShowComponentsInFooter" footer></Components>
    <v-layout row wrap id="external-links-container" :class="{'mt-3': $store.getters.getShowComponentsInFooter}">
      <v-flex
        xs10 sm12
        id="otras-hist-main-title"
        class="text-xs-center text-uppercase title-font title-text mt-4"
      >otras historias</v-flex>

      <otras-historias-carousel-3d class="hidden-xs-only"></otras-historias-carousel-3d>
      <v-flex xs10 class="hidden-sm-and-up">
        <a :href="$store.getters.getNextSlide.href" target="_blank" rel="noreferrer" class="oh-link">
          <v-img :src="$store.getters.getNextSlide.img" height="330" class="oh-photo">
            <v-layout
              column
              fill-height
              class="oh-title-container oh-title white--text px-4 title-font text-uppercase"
              justify-center
            >
              <span>{{ $store.getters.getNextSlide.titulo_top }}</span>
              <span>{{ $store.getters.getNextSlide.titulo_bottom }}</span>
            </v-layout>
          </v-img>
        </a>
      </v-flex>
      <v-flex v-if="show_actions" class="hidden-sm-and-up" style="margin-top: -30px;">
        <SideBar view_actions class="black"/>
      </v-flex>
      <v-layout row justify-end class="hidden-sm-and-up black pa-2">
        <v-btn outline icon dark class="oh-btn" @click="goPrevSlide()">
          <v-icon color="white">arrow_back_ios</v-icon>
        </v-btn>
        <v-btn outline icon dark class="oh-btn" @click="goNextSlide()">
          <v-icon color="white">arrow_forward_ios</v-icon>
        </v-btn>
      </v-layout>
    </v-layout>
    <!--<v-footer id="home-footer" height="auto">-->
    <v-container fluid column class="footer-content-container">
      <v-layout row wrap justify-center class="footer-actions">
        <v-flex class="metodologia text-sm-center" xs12 sm4>
          <v-btn
            outline
            dark
            class="footer-btn"
            icon
            target="_blank"
            rel="noreferrer"
            href="https://eltoque.com/como-se-hizo-el-especial-multimedia-flow-y-poder-el-regueton-en-cuba/"
          ></v-btn>
          <span class="text-uppercase title-font footer-btn-label">metodología</span>
        </v-flex>
        <!-- <v-flex class="offline offline-mb text-sm-center" xs12 sm3>
          <v-btn outline dark class="footer-btn" icon></v-btn>
          <span class="text-uppercase title-font footer-btn-label">offline</span>
        </v-flex> -->
      </v-layout>
      <v-layout row wrap class="px-2 py-3 text-xs-center">
        <v-flex xs12 sm6 class="white--text text-font" v-html="footer_text_top"></v-flex>
        <v-flex xs12 sm6 class="white--text text-font" v-html="footer_text_bottom"></v-flex>
      </v-layout>
      <v-layout row justify-center class="el-toque-redes-sociales">
        <div class="menu-brands-btn" v-for="(network, i) in eltoque_redes" :key="i">
          <a :href="network.url">
            <v-img :src="network.src"></v-img>
          </a>
        </div>
      </v-layout>
    </v-container>
    <!--</v-footer>-->
  </v-layout>
</template>

<script>
import OtrasHistoriasCarousel3d from "../../components/Home/OtrasHistoriasCarousel3d";
import Components from "../../components/Home/Components";
import SideBar from "../../components/Canciones/CancionesSidebar";

export default {
  name: "AppFooter",
  components: {
    OtrasHistoriasCarousel3d,
    Components,
    SideBar,
  },
  props: {
    view_footer_img: Boolean,
    footer_canciones: Boolean,
  },
  created() {
    if (this.view_footer_img) {
      this.footer_img_src = "/img/home/toolbar-bottom-bkg.jpeg";
    }
    this.$store.commit("nextSlide", this.slides[0]);
    this.current_slide = 0;

    this.show_actions = this.$route.name === "component.home";
    this.$router.afterEach(to => {
      this.show_actions = to.name === "component.home";
    });
  },
  data() {
    return {
      show_actions: false,
      footer_img_src: "",
      disabled_next_button: false,
      disabled_prev_button: true,
      footer_text_top:
        "Investigación y datos: Jesús Jank Curbelo, Sabrina López y Eduardo Gónzalez<br/>Diseño: Wendy Valladares y Janet Aguilar",
      footer_text_bottom:
        "Programación: Abraham Calás, Leduan Flores, Alain Alemany y Rolando Urquiza<br/>Coordinación editorial: Jessica Dominguez y José Jasán Nieves",
      slides: [
        {
          titulo_top: "Michel Boutic",
          titulo_bottom: "un promotor de reggaetón",
          img:
            "https://eltoque.com/wp-content/uploads/2019/05/michel_boutic-1.png",
          href: "https://eltoque.com/michel-boutic-un-promotor-de-reggaeton/"
        },
        {
          titulo_top: "Qué ha sido lo mas pegao",
          titulo_bottom: "del reguetón cubano",
          img:
            "https://eltoque.com/wp-content/uploads/2018/06/cuba-reparto-6.jpg",
          href:
            "https://eltoque.com/que-ha-sido-lo-mas-pegao-del-regueton-cubano/"
        },
        {
          titulo_top: "Cuatro cosas que todos deberíamos aprender",
          titulo_bottom: "del reguetón y de los reguetoneros",
          img:
            "https://eltoque.com/wp-content/uploads/2018/12/reguetoneros.jpg",
          href:
            "https://eltoque.com/cuatro-cosas-que-todos-deberiamos-aprender-del-regueton-y-de-los-reguetoneros/"
        },
        {
          titulo_top: "Antonella",
          titulo_bottom: "repartera cubana",
          img:
            "https://eltoque.com/wp-content/uploads/2018/11/Antonela_Reparto_Musica_Cuba_2018_elToque.jpg",
          href: "https://eltoque.com/antonella-repartera-cubana/"
        },
        {
          titulo_top: "Reparto, reguetón,",
          titulo_bottom: "cubanos pobres",
          img:
            "https://eltoque.com/wp-content/uploads/2018/06/cuba-reparto-6.jpg",
          href: "https://eltoque.com/reparto-regueton-cubanos-pobres/"
        },
        {
          titulo_top: "Emisora de música “repartera”",
          titulo_bottom: "transmite desde Nueva York y Lawton",
          img:
            "https://eltoque.com/wp-content/uploads/2019/08/Radio-Ragga-Morffa-Foto-cortes--a-del-entrevistado.jpg",
          href:
            "https://eltoque.com/emisora-de-musica-repartera-transmite-desde-nueva-york-y-lawton/"
        },
        {
          titulo_top: "ASIEL BABASTRO:",
          titulo_bottom: "EL REGUETÓN ES EL SOUNDTRACK DE CUBA",
          img:
            "https://eltoque.com/wp-content/uploads/2019/07/asiel-babastro.png",
          href:
            "https://eltoque.com/asiel-babastro-el-regueton-es-el-soundtrack-de-cuba/ "
        }
      ],
      current_slide: null,
      eltoque_redes: [
        {
          network: "facebook",
          url: "https://www.facebook.com/eltoquecom/ ",
          src: "/img/footer/eltoque-facebook.png"
        },
        {
          network: "twitter",
          url: "https://twitter.com/eltoquecom",
          src: "/img/footer/eltoque-twitter.png"
        },
        {
          network: "youtube",
          url: "https://www.youtube.com/eltoquecom ",
          src: "/img/footer/eltoque-youtube.png"
        },
        {
          network: "instagram",
          url: "https://www.instagram.com/eltoquecom/",
          src: "/img/footer/eltoque-instagram.png"
        },
        {
          network: "linkedin",
          url: "https://www.linkedin.com/company/eltoquecom/",
          src: "/img/footer/eltoque-linkedin.png"
        },
        {
          network: "telegram",
          url: "https://t.me/eltoquecom",
          src: "/img/footer/eltoque-telegram.png"
        },
        {
          network: "rss",
          url: "https://eltoque.com/feed/",
          src: "/img/footer/eltoque-rss.png"
        }
      ]
    };
  },
  methods: {
    goNextSlide: function() {
      this.current_slide = (this.current_slide + 1) % this.slides.length;
      this.disabled_prev_button = false;
      this.$store.commit("nextSlide", this.slides[this.current_slide]);
    },
    goPrevSlide: function() {
      this.disabled_next_button = false;
      if (this.current_slide == 0) {
        this.current_slide = this.slides.length - 1;
      } else {
        this.current_slide = (this.current_slide - 1) % this.slides.length;
      }
      this.$store.commit("nextSlide", this.slides[this.current_slide]);
    }
  }
};
</script>

<style>
/* .oh-desktop-visible {
  display: none;
} */
@media only screen and (min-width: 600px) {
  div#otras-hist-main-title {
    color: white;
    background: none;
  }

  #external-links-container {
    background-color: black;
  }

  div.footer-content-container {
    background-color: rgba(0, 0, 0, 0.9);
    margin-top: 16px;
    /* height: 20vw; */
  }
}

.oh-title {
  font-size: 24px;
  /* color: white; */
  letter-spacing: 1px;
  /* margin: 0 3vw; */
}

/* .top-title-margin {
        margin-top: 7vh
    } */

/* .oh-link {
  text-decoration: unset;
  width: 100%;
} */

#otras-historias {
  margin: 35px 35px 0 0;
  text-align: justify;
  padding-top: 10px;
}

#otras-hist-main-title {
  /* margin-left: 15px; */
  font-size: 20px;
  background: linear-gradient(90deg, #e63384, #5e4d9b);
}

.oh-title-container {
  /* padding: 0 40px; */
  background-color: rgba(0, 0, 0, 0.45);
  /* height: 100%; */
}

/* .oh-photo {
  width: 90vw!important;
  height: 330px;
  margin-right: 35px;
} */

.oh-top-header-img {
  height: 5vh;
}

/* #otras-hist-footer {
  background-color: black;
  padding: 10px;
} */

.oh-btn {
  border-radius: 0 !important;
  /* padding: 2.5vh 0; */
  width: 46px;
  border: solid 2px !important;
  margin-right: 0;
  margin-left: 0;
}

/* .oh-next-btn {
        margin-right: -2.5vw;
        padding-left: 4vw;
    } */

/* .oh-icon-container {
        padding-left: 60vw;
    } */

#home-footer {
  background-color: transparent;
  margin-top: 5vh;
}

/* .footer-text {
        margin-top: 20px;
        margin-left: 5px;
        color: white;
    } */

.footer-img {
  max-height: 55vh;
  margin-left: -3vw;
}

/* .footer-actions {
        margin-top: 32px;
    } */

.footer-content-container {
  background-color: black;
  /* height: 55vh; */
}

/* .footer-text-container {
        text-align: center;
        padding: 16px;
        position: relative;
        top: -6vw
    } */

/* .text-container-width {
        width: 100vw;
        padding: 0 15vw 2vw 5vw;
    } */

.footer-btn {
  width: 35px;
  height: 35px;
  border-radius: 0 !important;
  border: solid !important;
}

.footer-btn-label {
  color: white;
  letter-spacing: 4px;
  margin-left: 15px;
}

/* .footer-btn-margin {
        margin-left: 12vw;
    } */

/* .offline-mb {
        margin-bottom: 5vh;
    } */

/* .el-toque-redes-sociales {
        margin-bottom: 115px;
    } */

.menu-brands-btn {
  width: 50px;
}

.oh-header-title {
  margin-left: 32px;
}

@media only screen and (min-device-width: 0) and (max-device-width: 740px) {
  /* .footer-btn {
            color: white!important;
        } */

  /* .footer-text-spacer {
            margin-top: -12vw
        } */
}

@media only screen and (min-device-width: 0) and (max-device-width: 320px) {
  .footer-img {
    max-height: 75vh;
  }

  /* .footer-content-container {
            height: 75vh;
        } */

  /* .oh-btn {
            padding: 1vh 0;
        } */

  /* .oh-photo {
            width: 89.1vw!important;
            height: 20vh;
        } */
}

@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
  .footer-img {
    max-height: 65vh;
  }

  /* .footer-content-container {
            height: 82vh;
        } */

  /* .oh-photo {
            width: 90.3vw!important;
            height: 40vh;
        } */

  /* .oh-title-container {
            height: 40vh;
            padding-top: 8vh;
        } */

  /* .oh-btn {
            padding-top: 0;
            padding-bottom: 1vh;
        } */
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  /* .otras-historias-artistas-desktop {
    margin-top: 4vh;
    width: 100vw;
    position: relative;
  } */

  /* .otras-historias-canciones-desktop {
    right: 0;
  } */

  .footer-transparent {
    background-color: transparent !important;
  }

  #otras-historias {
    background-color: transparent;
    color: white;
    text-align: center;
  }

  /* .otras-historias-container {
    background-color: black;
  } */

  /* #otras-hist-main-title {
    background: transparent !important;
  } */

  /* .oh-desktop-visible {
    display: flex;
  } */

  /* .oh-mobile-visible {
    display: none;
  } */

  #home-footer {
    margin-top: 0;
    height: 45vh;
  }

  /* .footer-container-row {
            -webkit-box-orient: horizontal!important;
            -webkit-box-direction: normal!important;
            -ms-flex-direction: row!important;
            flex-direction: row!important;
            display: flex;
            display: -moz-flex;
            display: -webkit-flex;
        } */

  .text-container-width {
    min-width: 35vw;
    padding: 0;
    margin: 0;
  }

  /* .footer-text-container {
            margin: 0 15vw 0;
            top: 0
        } */

  /* .footer-text {
            color: white;
            margin-top: 0;
        } */

  /* .footer-btn {
            width: 3vw;
            height: 6vh;
            color: white;
        } */

  .footer-btn-label {
    color: white;
  }

  /* .footer-btn-margin {
            margin-left: 0;
        } */

  /* .footer-actions {
            position: relative;
            top: 2vw;
            max-width: 40vw;
            left: 35vw;
            margin-bottom: 0;
            margin-top: 0;
        } */

  /* .footer-text-spacer {
            max-width: 0;
        } */

  /* .el-toque-redes-sociales {
            margin: 0 32vw 2vw;
            position: relative;
            top: 0;
        } */

  /* .menu-brands-btn {
            height: 50px;
            width: 50px;
            margin: 0 1vw;
        } */

  .components-box-container {
    margin: 2vw 0 !important;
  }
}
</style>
