import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    home_header_description: "",
    component: "",
    songYear: "",
    canciones: null,
    loadedSongs: false,
    listadoArtistas: null,
    topFivePosicion: null,
    topFiveRedes: null,
    loadedArtistas: false,
    datosGrafo: null,
    topFiveGrafo: null,
    loadedGraph: false,
    datosPosicion: null,
    loadedPosicion: false,
    datosRedes: null,
    loadedRedes: false,
    datosPremios: null,
    loadedPremios: false,
    loadedFullArtistas: false,
    view_year: null,
    listaFavoritos: [],
    listaCanciones: [],
    titulosPrincipales: [],
    titulosSecundarios: [],
    titulosSecundariosPartitioned: [],
    data_by_filter: [],
    show_filtered_data: false,
    filter_list: [],
    comentarios: [],
    showModal: false,
    show_favoritos_modal: false,
    slide: null,
    youtube_link: "",
    view_info_text: false,
    view_camino_info: false,
    view_destino_info: false,
    view_third_info: false,
    camino_data: [
      {
        id: 1,
        name: "artistas",
        src: "/img/money/caminos-info/mobile/artista.jpg",
        presentation_text:
          "Un artista tiene una idea, tiene algo que decir, o decide comprar o interpretar la letra de alguien más. A lo mejor la ha cantado antes o la improvisa.",
        main_text:
          "El artista es la base de la cadena. A partir de él y sus canciones, se despliega el resto de la industria y se articulan las demás profesiones. Sin embargo, no todos son iguales: se dividen en estratos invisibles, por sus ganancias, niveles de promoción, lugares y precios que cobran por presentación." +
          "<br/><br/>Además, no trabajan solos. Por lo general tienen un equipo de trabajo que se compone de un DJ en tarima, un representante, un mánager productor, coristas para actuaciones, equipo de seguridad personal, chofer para las actuaciones, bailarines en conciertos y un asistente personal." +
          "<br/><br/>Entre el año 2010 y 2018, de más de 600 canciones populares identificadas por este medio, se pueden contabilizar 155 artistas diferentes.",
        corto: true
      },
      {
        id: 2,
        name: "estudio",
        src: "img/money/caminos-info/mobile/estudio.jpg",
        presentation_text:
          "Pero hay que grabar para que la canción permanezca, se pueda escuchar y compartir. Entonces, necesita de un estudio de grabación. Existen cientos de ellos, nadie lo sabe exactamente y su calidad, formato y precios es variable.",
        main_text:
          "No se necesita un estudio profesional; con una computadora, un pequeño micrófono y un programa de edición de audio, en una habitación cualquiera, se graba una canción que después puede distribuirse por teléfono o en las redes sociales." +
          "<br/><br/>Por lo general se fundan con escasos recursos y los dueños y creadores hacen de DJ, relacionistas públicos y especialistas empíricos de marketing. Los precios iniciales varían, pero oscilan entre 3 o 5 CUC por canción. A veces, es gratis." +
          "<br/><br/>La masividad y baja calidad en las producciones caracteriza esta fase inicial. En la medida en que llegan más clientes, aumentan los precios y las ganancias, cambian las rutinas de producción, promoción y distribución." +
          "<br/><br/>Advanced Studio, uno de los estudios mejor posicionados en el Reparto, por ejemplo, cobra en la actualidad 80 CUC por una canción completa, lo cual incluye la promoción nacional e internacional, en el canal de Youtube y el Paquete." +
          "<br/><br/>Mientras Galaxy Music —otro caso destacado— empezó cobrando tres CUC por la producción y después creció a ocho. Ahora oscila entre 60 u 80 CUC por background. Para artistas de otros países, el precio es de 150 o 200 CUC. En dependencia del pedido, el estudio hace ofertas: en un disco de 10 canciones, en vez de costar 60 por canción, a cada tema se le pueden rebajar 5 o 10 CUC." +
          "<br/><br/>En el caso de Célula Music, el más famoso de los estudios de la Farándula, sus tarifas son muy superiores: 400 CUC por la producción de una canción, lo cual incluye su promoción." +
          "<br/><br/>Pero no solo se graba en Cuba, los cantantes de la Isla graban en Miami y los de Miami en Cuba. Importantes sellos discográficos como Planet Records y Urban Latin Records incluyen en su catálogo algunas de las estrellas del reguetón cubano." +
          "<br/><br/>Unido a algunos estudios han aparecido los promotores: adolescentes y jóvenes que socializan la música obtenida directo de los estudios de grabación. Comienzan sin cobrar nada, repartiendo la música gratis, pero en algunos casos reciben pagos." +
          "<br/><br/>Estudios de grabación como el de Vladimir Companioni, radicado en Estados Unidos, por la producción de una canción completa cobra 2000 dólares.",
        corto: true
      },
      {
        id: 3,
        name: "video",
        src: "img/money/caminos-info/mobile/video.jpg",
        presentation_text:
          "En esta época no basta con escuchar, hay que ver el producto. Un video clip amplía las posibilidades de difusión de la canción y del artista, se pega mejor; aunque no todas las canciones tienen un video.",
        main_text:
          "Para los productores de video, los precios son superiores. Varían de acuerdo a las cámaras, uso de luces, locación y transporte. Flow Nativo explica que un video, dependiendo de estos parámetros, puede costar más de 2000 CUC. Pero incluso, como ocurre en el caso de la música, un grupo de amigos se reúne y graba un video pequeño, sin muchos recursos, más allá de “1 CUC para pagar algo de comer a una bailarina”." +
          "<br/><br/>Rowa DJ (productor musical y de videos) puede hacer materiales audiovisuales desde 80 CUC, considerado un video sencillo, pero que cumple con la idea de dar promoción. Otros videos pueden costar cientos de dólares." +
          "<br/><br/>Advanced Studio también elabora videos que pueden oscilar alrededor de 900 CUC, dependiendo de lacalidad, exigencias de los clientes y el gasto." +
          "<br/><br/>No obstante, un video podría cifrarse en precios de miles de CUC, como el caso de Yomil y el Dany, quienes reconocieron en entrevista a OnCuba que “nuestros videos cuestan entre 7 mil y 10 mil CUC”. El financiamiento de estos videos, afirma el dúo, proviene de su canal de Youtube." +
          "<br/><br/>Célula Music tiene a sus muchachos que brindan el servicio de video. Dentro del estudio su precio oscila entre los 150 y 200 CUC, mientras que en exteriores sobrepasa los 4 000 CUC o 5 000 CUC." +
          "<br/><br/>El reguetón “es el único mercado estable, probado y sostenible ahora mismo”, dice Asiel Babastro, conocido realizador cubano de videos, que ha trabajado con Diván y El Chacal.",
        corto: false
      },
      {
        id: 4,
        name: "agente",
        src: "img/money/caminos-info/mobile/agente.jpg",
        presentation_text:
          "Son invisibles, pero muy importantes, sin ellas los artistas no se pueden presentar en conciertos, no se les puede contratar. Hay varios tipos, pero en general, representan al artista legalmente.",
        main_text:
          "A pesar de los tabúes, una parte de sus artistas pertenecen actualmente a una empresa o agencia de representación cubanas y pueden presentarse tranquilamente en clubes, bares y centros nocturnos de Cuba, y pagan sus impuestos. No obstante, la mayoría de los cantantes del Reparto no pertenecen a dichas agencias y, por tanto, “no son legales”. En ese caso se presentan como artistas aficionados e invitados por proyectos artísticos u otros artistas que sí son legales." +
          "<br/><br/>Por ejemplo, Yomil y el Dany integran el catálogo de la Empresa Comercializadora de la Música y los Espectáculos Ignacio Piñeiro. En el caso de Musicalia, Agencia de Representación de Artex, tiene en su catálogo a famosos como Diván, Jacob Forever y Los 4." +
          "<br/><br/>Los artistas más establecidos suelen presentarse por un precio previamente acordado. De lo obtenido en su presentación, él recibirá una parte, mientras la agencia de representación se queda con otra por sus servicios." +
          "<br/><br/>“Las instituciones ejecutan sus servicios asumiendo gastos a partir de los ingresos que se generen por este mismo concepto, correspondiendo hasta el treinta por ciento (30 %) del valor del Contrato de Servicios Artísticos pactado a la institución, según el grado de su participación en la gestión del contrato y otros en los que incurra para la comercialización de sus presentaciones en el territorio nacional”." +
          "<br/><br/>“La Unidad Artística por su parte recibe el resto de los ingresos percibidos por la institución de acuerdo a lo suscrito en el Contrato de Servicios Artísticos, lo que es remunerado en la moneda y mediante la utilización de los instrumentos de pago establecidos a estos efectos”. Explica la Gaceta Oficial Extraordinaria, de octubre de 2013”." +
          "<br/><br/>Las ganancias de los artistas son catalogadas como ingresos personales y, por tanto, sujeto a impuestos posteriores.",
        corto: false
      },
      {
        id: 5,
        name: "paquete",
        src: "img/money/caminos-info/mobile/paquete.jpg",
        presentation_text:
          "Si no estás en el paquete, no existes. A este mecanismo offline de distribución llega toda la música que se produce en Cuba.",
        main_text:
          "Mediante el Paquete semanal, el servicio de distribución offline más importante en Cuba, llega la música a todos rincones del país. Esta llega a las matrices a través de los propios artistas o los productores de estudios, quienes pagan por colocarlas ahí." +
          "<br/><br/>No se sabe con exactitud cuántas canciones se producen en el año; muchas de ellas son temas sueltos o nuevos cantantes que intentan probar suerte y ni los artistas ni los estudios las tienen compiladas. Un análisis general de los contenidos del Paquete semanal durante el año 2018 nos indica que de 100 705 canciones que contenían, había 264 discos de reguetón y 3817 canciones sueltas.",
        corto: true
      },
      {
        id: 6,
        name: "medios",
        src: "img/money/caminos-info/mobile/medios.jpg",
        presentation_text:
          "Otra forma de oír música es a través de os medios de comunicación, en la radio y la televisión o en canales como Youtube o Spotify en Internet, depende de lo que te guste más y puedas acceder.",
        main_text:
          "Los productores, sin embargo, intentan acabar con la dependencia del Paquete y sus circuitos de distribución. Se han lanzado a crear sus redes sociales, en las cuales difunden las canciones y los videos. Están presente, principalmente, en Facebook, con páginas personales para difundir las nuevas creaciones y dar promoción a los artistas." +
          "<br/><br/>También los estudios han creado canales de Youtube como Galaxy Music y Advanced Studio; este con más de 10000 suscriptores. Ellos lanzan los videos o realizan adelantos de las próximas canciones, creando un ambiente favorable para su recepción. Advanced, incluso, monetizó su canal, mientras que Galaxy no cobra absolutamente nada. Lo mismo hace Flow Nativo, que comparte videos y que, además, tiene su página web." +
          "<br/><br/>Muchos artistas, entre ellos El Chacal y el Tiger, tienen sus canales propios de Youtube; algunos de los cuales fueron monetizados, como el caso de Yomil y el Dany." +
          "<br/><br/>Otras de las formas de distribución curiosa es la del Ruso, al frente de Advanced Studio, quien realiza sus propias entrevistas televisivas a nacientes estrellas de la música del reparto y las promueve por sus canales de Youtube." +
          "<br/><br/>Además, la televisión y la radio mantienen sus habituales espacios de información y promoción musical; mucho más accesible al público nacional, pero más selectivo y de difícil acceso para algunos exponentes del género. Las canciones de reparto son marginadas de estos espacios.",
        corto: false
      },
      {
        id: 7,
        name: "concierto",
        src: "img/money/caminos-info/mobile/concierto.jpg",
        presentation_text:
          "De frente, en vivo, se disfruta mejor la música. Un concierto de tu artista preferido es lo máximo.",
        main_text:
          "El reguetón posee un amplio circuito de presentación por toda Cuba, en bares privados, centros nocturnos estatales y cabarets, quizás como ningún otro género." +
          "<br/><br/>Los precios de entrada para los conciertos varían de acuerdo con el lugar y el artista, sea de Reparto o de Farándula. Estos oscilan entre los 2 CUC o 50 pesos en moneda nacional, hasta los 50 CUC." +
          "<br/><br/>Los bares privados habaneros como el Mío y Tuyo, Olalá y Fantaxy se han convertido en sitios regulares de presentación. Algunos de ellos son asiduos, como Diván en Olalá o El Taiger en Mío y Tuyo." +
          "<br/><br/>También actúan en centros estatales o cabarets de hoteles habaneros, como Don Cangrejo, La Cecilia, Salón Rosado de la Tropical, Habana Café del Meliá Cohiba y el Salón Rojo del Capri." +
          "<br/><br/>En otras provincias del país, suelen actuar en plazas abiertas o cabarets de gran capacidad, como el Costa Sur en Cienfuegos, Rumayor en Pinar del Río u hoteles de Varadero." +
          "<br/><br/>En esta investigación fueron rastreados y ubicados unos 109 conciertos de 11 artistas o agrupaciones del género, en 11 lugares de presentación, desde principios de 2018 hasta febrero de 2019.",
        corto: false
      }
    ],
    current_camino_id: 1,
    current_camino_data: null,
    current_camino_img: "",
    current_camino_text: "",
    current_camino_main_text: "",
    camino: "",
    best_song_year: "",
    best_songs: [
      {
        year: "2010",
        name: "chupi chupi"
      },
      {
        year: "2011",
        name: "a la my love"
      },
      {
        year: "2012",
        name: "el yonki"
      },
      {
        year: "2013",
        name: "el taxi"
      },
      {
        year: "2014",
        name: "bailando"
      },
      {
        year: "2015",
        name: "guachineo"
      },
      {
        year: "2016",
        name: "hasta que se seque el malecón"
      },
      {
        year: "2017",
        name: "palón divino"
      },
      {
        year: "2018",
        name: "bajanda"
      }
    ],
    showToolbarTitle: false,
    showComponentsInFooter: false,
    viewCancionesBox: true,
    viewArtistasBox: true,
    viewLasBalasBox: true,
    viewJuegoBox: true,
    viewPerfilArtista: false,
    currentArtista: {},
    currentSong: null,
    year_data: [
      {
        year: "2010",
        text:
          "Osmani García, también conocido como La Voz, había reunido a los cantantes Jose El Pillo, Yohan Manuel Entre Dos, El Chacal, Kola Loka y El Micha para el tema El Pudín, en 2010. Ese año agrupó a Blad Mc, William El Magnífico, Chocolate, Insurrecto, Patry White, Yulién Oviedo, El Macri y Eric White para la canción Chupi chupi. Luego de las declaraciones del Ministro de Cultura de esa época, Abel Prieto, en las que denunció públicamente la letra y el video, expresando que el reguetón “había puesto el alma de la nación en la balanza”, el Chupi chupi fue anulado de los candidatos a obtener el Premio Lucas en la categoría Elección popular. Ante la crítica, Osmani García presentó una carta abierta de protesta." +
          "En otra escena, durante ese período en calles más humildes sonaba El Campismo (Parapapampan). Chocolate y El Úniko se unían para incorporar las palmadas, el sonido de la clave cubana y el compás musical de 3x4, lo cual dio inicio al reparto como género dentro del reguetón cubano. " +
          "<br/><br/>Mientras, otros reguetoneros se posicionaban en el evento principal del video clip cubano: los Premios Lucas. Cerro cerrado, de Insurrecto, con un video dirigido por José Albelo se llevó el galardón a Video del año, Video más popular y Video reguetón. Baby Lores con Ya no hacemos el amor resultó premiado en la categoría de Mejor fotografía."
      },
      {
        year: "2011",
        text:
          "Ellas son locas, de Chacal y Yakarta, puso al dúo en la mira del público, tanto a admiradores como a detractores. Fue uno de los primeros videos nacionales en el cual aparecen mujeres semidesnudas besándose, lanzando dinero... Lo censuraron debido a las controversiales imágenes; el mismo destino tuvo su tema La corrupción.\n" +
          "Decir “estoy a la my love” fue una frase muy popular durante ese año. El grupo Kola Loka la puso de moda con la canción homónima. Su significado hacía referencia a bienestar, satisfacción, suerte. Su video clip fue nominado al Video más popular en los Premios Lucas 2011. En el certamen, El Micha ganó las categorías de Artista novel y Video reguetón con su sencillo Con dinero y pasma’o.\n" +
          "<br/><br/>En 2011 también se produce la unión de El Príncipe (ahora conocido como El Taiger) junto a Damián. Al separarse del grupo Los 4, que compartían con Jorge Junior, deciden comenzar un proyecto bajo el nombre Los Desiguales. A partir de esta fecha, la música urbana cubana tomó un nuevo giro, pues Los Desiguales popularizaron el Fashatón: la unión de la música urbana junto a una forma de vestir elegante y costosa, algo que no se había visto anteriormente en el país."
      },
      {
        year: "2012",
        text:
          "El Yonki fue el sencillo de mayor éxito del cantante homónimo. Con él logró sobrepasar el millón de visitas en YouTube y obtener premios en el Cubadisco, así como una nominación en los Lucas, en la categoría de Artista novel. Por la canción se puso de moda un pelado de igual nombre: pelo hacia arriba (lo más alto posible) en forma de cresta y los laterales rapados. Igualmente, se puso de moda el baile que el cantante hacía en el video. \n" +
          "Ese año el reguetón escaló en las plataformas oficiales de la música cubana. Bom Bom de Baby Lores quedó finalista durante la XV gala de los Premios Lucas en las categorías de Video más popular y Video reguetón; Otra dimensión, de Los Desiguales, resultó nominado en Video música pop/house/electrónica y Video artista novel, mientras Gente de Zona, con el video clip Salte del sartén, dirigido por Alejandro Pérez, obtuvo el Video más popular durante la gala."
      },
      {
        year: "2013",
        text:
          "El taxi, de Osmani García junto al cubanoamericano Pitbull y el dominicano Sensato del Patio, fue el primer tema producido por latinos en cerrar unos Premios Grammy (edición 58), y aunque había sido interpretado en reconocidas galas, nunca lo había hecho para un evento dirigido al público anglófono. Esto le concedió a Osmani García el primer puesto dentro de los artistas cubanos en posicionar el reguetón dentro de Estados Unidos. \n" +
          "Mientras, en Cuba, Baby Lores arrasaba en los Premios Lucas en el apartado de Video música pop/house/electrónica con el sencillo Cantaré y como Mejor video música pop junto a Thaira por el video de Imagina que soy."
      },
      {
        year: "2014",
        text:
          "Bailando, colaboración entre Gente de Zona, Descemer Bueno y el cantante español Enrique Iglesias, lanzó al dúo cubano a la fama internacional. La canción llegó a ser número uno del Billboard Top Latin Songs y ganadora de tres Premios Grammy Latinos, incluido Mejor canción del año. El video superó el billón de vistas en YouTube, siendo el primer tema en español en obtener tal cifra hasta ese momento. También fue laureado en los Premios Juventud en la categoría Mi Letra Favorita y al cierre de 2014 fue la décima canción más vendida en el mundo. Sin embargo, durante la premiación de los Lucas, solo fue seleccionada ganadora como Mejor making off y Mejor video coreográfico, quedando solamente nominada en las categorías Video del año y Video más popular, decisión que causó descontento entre muchos después de la repercusión y trascendencia que había alcanzado.\n" +
          "Gente de Zona, entonces, estaba compuesto por Alexander Delgado y Randy Malcolm, un joven procedente de La Charanga Habanera. Jacob Forever comenzó un proyecto junto a El Danny y el productor Nando Pro.\n" +
          "<br/><br/>Ese año, Bla bla bla, de El Micha, fue el único video de reguetón en lograr una estatuilla en los Lucas, en la categoría Mejor video de reguetón."
      },
      {
        year: "2015",
        text:
          "Chocolate, con su canción Guachineo, revolucionó el reparto en Cuba y llegó a países como Noruega, Suecia, Italia, España y Estados Unidos. Todavía se desconoce el origen etimológico de la palabra y su posible significado, pero sí está claro que bailar con la punta del pie y otros movimientos realizados por el cantante durante el video clip, se impusieron en el baile de este tema y a partir de aquí, en la mayoría de las canciones y seguidores del género. \n" +
          "<br/><br/>En la esfera internacional, el dúo Gente de Zona arrasaba con La Gozadera, junto al boricua Marc Anthony. Este tema resultó nominado a los Premios Lo Nuestro 2016 como Video del año y ganador del Premio Juventud en los apartados Mi Letra Favorita y La Combinación Perfecta. Logró posicionarse como número uno en numerosas listas del continente y en España fue, durante dos meses, la canción más escuchada. Con ella, Gente de Zona también recibió tres premios de la Sociedad Americana de Autores, Compositores y Publicistas. Poco después, Gente de Zona y Marc Anthony lanzaron Traidora, compuesto por Leoni Torres. A una semana de estrenado en YouTube, ya contaba con más de 700 mil visualizaciones.  \n" +
          "<br/><br/>Para Yomil y El Danny, 2015 fue un año de triunfo. Antes, el dúo había tenido poco éxito bajo el nombre D punto D, y habían decidido separarse. Yomil pasó a formar parte de Los 4, junto a Jorge Junior y Norlan, y El Danny se había integrado al proyecto de Jacob Forever. Una vez reunidos, fueron los primeros exponentes del género Trapton: un derivado del reguetón que combina trap, rap, música electrónica, entre otros. Con el controversial nombre de Doping titularon su primer disco, debido, según ellos, a “la necesidad de que nuestra música sea contagiosa y resulte una droga para todo el que la escuche”."
      },
      {
        year: "2016",
        text:
          "La expresión “hasta que se seque el malecón” se fijó en la jerga de gran cantidad de personas que aludían a disímiles situaciones: usada frecuentemente para indicar temporalidad indefinida. Es una reivindicación de “darle fuego a la lata”, que significa ir “pa´lante” hasta que no se pueda más. Jacob Forever, junto al puertorriqueño Farruko, obtuvieron un disco de platino con el remix de esta canción. El video obtuvo reconocimiento como el más popular en los Premios Lucas 2016.\n" +
          "Ay, mi Dios sencillo de El Chacal, Pitbull y el boricua Yandel, fue estrenado en la décima gala del concurso Nuestra Belleza Latina y se alzó con el premio Latin Tropical de Billboard. Aunque el tema es de la autoría de El Chacal, durante la presentación en los Premios Juventud 2016 fue excluida la parte donde cantaba en solitario, y solamente hizo los coros."
      },
      {
        year: "2017",
        text:
          'El Palón Divino de Chocolate tuvo una repercusión impactante tanto en Cuba como en otros países. Un fragmento de esta fue utilizado por la doctora Ana María Polo para introducir uno de sus casos. El artista compartió el video en sus redes sociales y agradeció a la doctora Polo por utilizar su canción. “No pensé nunca llegar tan lejos. Gracias, doctora Ana María Polo. Gracias, mi público, sin su apoyo nada fuera posible", dijo. A partir de este tema se genera otro sinnúmero de canciones que mencionan al palón, al palón divino y al palito, no solo por parte de Chocolate sino de otros cantantes del género que emergían. Entre ellos, Negrito, Kokito y Manu Manu, autores del éxito El palito presidiario.\n' +
          "Por otra parte, Yomil y El Danny, a pesar de no tener presentaciones fuera del país hasta la fecha, en 2017 fueron nominados a los Billboard en la categoría Latin Rhythm Albums Artista del año dúo o grupo."
      },
      {
        year: "2018",
        text:
          "Bajanda fue otro hit de Chocolate. A partir del auge que toma el reparto en Cuba, su extensión a Miami y a Europa, emergen nuevos intérpretes del género como Popy & La Moda, El Kamel, Harryson y Un Titiko. Se presupone que la canción es una “tiradera” a los reparteros nuevos que creían ser los reyes del reparto. Por eso, Chocolate les advierte que “déjense de gracia con la corona” o “soy el originanda, ustedes están inflanda, ahora recoge los chelines que es bajanda” porque él “es el rey del reparto completo”. \n" +
          "En los Premios Juventud 2018 se dedicó un espacio por primera vez al reguetón de Cuba en un evento de gran magnitud. El Micha, Jacob Forever, El Chacal, El Taiger, Yomil y El Danny, Leiner Mesa, Yulién Oviedo, IAM Chino y La Señorita Dayana subieron juntos al escenario a interpretar el tema Cuba está de moda."
      }
    ]
  },
  mutations: {
    changeHomeHeaderDescription(state, home_header_description) {
      state.home_header_description = home_header_description;
    },
    changeComponent(state, component) {
      state.component = component;
    },
    addFavorito(state, element) {
      state.listaFavoritos.push(element);
    },
    songYear(state, year) {
      state.songYear = year;
    },
    canciones(state, data) {
      state.canciones = data;
      state.loadedSongs = true;
    },
    setViewYear(state, value) {
      state.view_year = value;
    },
    clearListaCanciones(state) {
      state.listaCanciones = [];
    },
    clearTitulosPrincipales(state) {
      state.titulosPrincipales = [];
    },
    clearTitulosSecundarios(state) {
      state.titulosSecundarios = [];
    },
    clearTitulosSecundariosPartitioned(state) {
      state.titulosSecundariosPartitioned = [];
    },
    addCancion(state, element) {
      state.listaCanciones.push(element);
    },
    addComentarios(state, payload) {
      state.comentarios.push(payload);
    },
    showModal(state, value) {
      state.showModal = value;
    },
    nextSlide(state, value) {
      state.slide = value;
    },
    addTituloPrincipal(state, value) {
      state.titulosPrincipales.push(value);
    },
    addTituloSecundario(state, value) {
      state.titulosSecundarios.push(value);
    },
    addToTitulosSecundariosPartitioned(state, value) {
      state.titulosSecundariosPartitioned.push(value);
    },
    addFilteredData(state, value) {
      state.data_by_filter.push(value);
    },
    setShowFilteredData(state, value) {
      state.show_filtered_data = value;
    },
    addFilter(state, value) {
      state.filter_list.push(value);
    },
    clearFilteredData(state) {
      state.data_by_filter = [];
    },
    clearFilterList(state) {
      state.filter_list = [];
    },
    youtubeLink(state, value) {
      state.youtube_link = value;
    },
    showFavoritosModal(state, value) {
      state.show_favoritos_modal = value;
    },
    setViewInfoText(state, value) {
      state.view_info_text = value;
    },
    setViewDestinoInfo(state, value) {
      state.view_destino_info = value;
    },
    setViewThirdInfo(state, value) {
      state.view_third_info = value;
    },
    setViewCaminoInfo(state, value) {
      state.view_camino_info = value;
    },
    setCurrentCaminoId(state, value) {
      state.current_camino_id = value;
    },
    setCurrentCaminoData(state, value) {
      state.current_camino_data = value;
    },
    setCurrentCaminoImg(state, value) {
      state.current_camino_img = value;
    },
    setCurrentCaminoText(state, value) {
      state.current_camino_text = value;
    },
    setCurrentCaminoMainText(state, value) {
      state.current_camino_main_text = value;
    },
    setCurrentCamino(state, value) {
      state.camino = value;
    },
    setBestSongYear(state, value) {
      state.best_song_year = value;
    },
    setShowToolbarTitle(state, value) {
      state.showToolbarTitle = value;
    },
    setShowComponentsInFooter(state, value) {
      state.showComponentsInFooter = value;
    },
    setViewCancionesBox(state, value) {
      state.viewCancionesBox = value;
    },
    setViewArtistasBox(state, value) {
      state.viewArtistasBox = value;
    },
    setViewLasBalasBox(state, value) {
      state.viewLasBalasBox = value;
    },
    setViewJuegoBox(state, value) {
      state.viewJuegoBox = value;
    },
    setArtistasCubanos(state, value) {
      state.artistasCubanos.push(value);
    },
    clearArtistasList(state) {
      state.artistasCubanos = [];
    },
    clearCancionesPopulares(state) {
      state.cancionesPopulares = [];
    },
    setCancionesPopulares(state, value) {
      state.cancionesPopulares.push(value);
    },
    setViewPerfilArtista(state, value) {
      state.viewPerfilArtista = value;
    },
    setCurrentArtista(state, value) {
      state.currentArtista = value;
    },
    playSong(state, value) {
      state.currentSong = value;
    },
    setDatosPosicion(state, data) {
      state.datosPosicion = data;
      state.loadedPosicion = true;
    },
    setDatosRedes(state, data) {
      state.datosRedes = data;
      state.loadedRedes = true;
    },
    setDatosPremios(state, data) {
      state.datosPremios = data;
      state.loadedPremios = true;
    },
    setDatosGrafo(state, { general, topFive }) {
      state.datosGrafo = general;
      state.topFiveGrafo = topFive;
      state.loadedGraph = true;
    },
    setListadoArtistas(state, { general, topFivePos, topFiveRedes }) {
      state.listadoArtistas = general;
      state.topFivePosicion = topFivePos;
      state.topFiveRedes = topFiveRedes;
      state.loadedArtistas = true;
    },
    setLoadedFullArtistas(state, value) {
      state.loadedFullArtistas = value;
    }
  },
  getters: {
    home_header_description: state => state.home_header_description,
    component: state => {
      return state.component;
    },
    listaFavoritos: state => state.listaFavoritos,
    songYear: state => state.songYear,
    getViewYear: state => state.view_year,
    canciones: state => state.canciones,
    listaCanciones: state => {
      return state.listaCanciones;
    },
    titulosPrincipales: state => {
      return state.titulosPrincipales;
    },
    titulosSecundarios: state => {
      return state.titulosSecundarios;
    },
    getTitulosSecundariosPartitioned: state => {
      return state.titulosSecundariosPartitioned;
    },
    comentarios: state => {
      return state.comentarios;
    },
    showModal: state => {
      return state.showModal;
    },
    getNextSlide: state => {
      return state.slide;
    },
    getFilteredData: state => {
      return state.data_by_filter;
    },
    showFilteredData: state => {
      return state.show_filtered_data;
    },
    getFilterList: state => {
      return state.filter_list;
    },
    getYoutubeLink: state => {
      return state.youtube_link;
    },
    getShowFavoritosModal: state => {
      return state.show_favoritos_modal;
    },
    getViewInfoText: state => {
      return state.view_info_text;
    },
    getViewDestinoInfo: state => {
      return state.view_destino_info;
    },
    getViewThirdInfo: state => {
      return state.view_third_info;
    },
    getViewCaminoInfo: state => {
      return state.view_camino_info;
    },
    getCurrentCaminoId: state => {
      return state.current_camino_id;
    },
    getCurrentCaminoData: state => {
      return state.current_camino_data;
    },
    getCaminoData: state => {
      return state.camino_data;
    },
    getCurrentCaminoImg: state => {
      return state.current_camino_img;
    },
    getCurrentCaminoText: state => {
      return state.current_camino_text;
    },
    getCurrentCaminoMainText: state => {
      return state.current_camino_main_text;
    },
    getCurrentCamino: state => {
      return state.camino;
    },
    getBestSongYear: state => {
      return state.best_song_year;
    },
    getShowToolbarTitle: state => {
      return state.showToolbarTitle;
    },
    getShowComponentsInFooter: state => {
      return state.showComponentsInFooter;
    },
    getViewCancionesBox: state => {
      return state.viewCancionesBox;
    },
    getViewArtistasBox: state => {
      return state.viewArtistasBox;
    },
    getViewLasBalasBox: state => {
      return state.viewLasBalasBox;
    },
    getViewJuegoBox: state => {
      return state.viewJuegoBox;
    },
    getViewPerfilArtista: state => {
      return state.viewPerfilArtista;
    },
    getCurrentArtista: state => {
      return state.currentArtista;
    },
    getYearData: state => {
      return state.year_data;
    },
    playingSong: state => {
      return state.currentSong;
    },
    loadedSongs: state => state.loadedSongs,
    loadedArtistas: state => state.loadedArtistas,
    loadedPosicion: state => state.loadedPosicion,
    loadedRedes: state => state.loadedRedes,
    loadedPremios: state => state.loadedPremios,
    loadedGraph: state => state.loadedGraph,
    loadedFullArtistas: state => state.loadedFullArtistas,
    datosArtistas: state => ({
      artistas: state.listadoArtistas,
      grafo: { topFive: state.topFiveGrafo, general: state.datosGrafo },
      posicion: {
        topFive: state.topFivePosicion,
        general: state.datosPosicion
      },
      redes: { topFive: state.topFiveRedes, general: state.datosRedes },
      premios: state.datosPremios
    })
  },
  actions: {
    GET_DATOS_POSICION({ commit }) {
      return import(
        /* webpackChunkName: "data-pos" */ "./assets/artistasData/artistspos.json"
      ).then(module => {
        commit("setDatosPosicion", module.default);
      });
    },
    GET_DATOS_REDES({ commit }) {
      return import(
        /* webpackChunkName: "data-redes" */ "./assets/artistasData/redes.json"
      ).then(module => {
        commit("setDatosRedes", module.default);
      });
    },
    GET_DATOS_PREMIOS({ commit }) {
      return import(
        /* webpackChunkName: "data-premios" */ "./assets/artistasData/premios.json"
      ).then(module => {
        const data = module.default;
        data.sort((a, b) =>
          a.ganados < b.ganados ? 1 : a.ganados > b.ganados ? -1 : 0
        );
        commit("setDatosPremios", data);
      });
    },
    GET_ARTISTAS_GRAFO({ commit }) {
      return import(
        /* webpackChunkName: "data-graph" */ "./assets/artistasData/artistsgraph.json"
      ).then(module => {
        const data = module.default;
        data.sort((a, b) =>
          a.betweeness < b.betweeness ? 1 : a.betweeness > b.betweeness ? -1 : 0
        );
        commit("setDatosGrafo", { general: data, topFive: data.slice(0, 5) });
      });
    },
    async GET_ARTISTAS({ dispatch, commit, state }) {
      if (!state.loadedSongs) {
        await dispatch("GET_CANCIONES");
      }
      if (!state.loadedGraph) {
        await dispatch("GET_ARTISTAS_GRAFO");
      }
      if (!state.loadedPremios) {
        await dispatch("GET_DATOS_PREMIOS");
      }

      const listadoArtistas = [];
      const topFivePosicion = [];
      const topFiveRedes = [];

      const { default: artistas } = await import(
        /* webpackChunkName: "data-artists" */ "./assets/artistasData/artistas.json"
      );
      let index = 0;
      artistas.forEach(artista => {
        if (
          artista !== undefined &&
          artista.tipo === "regular" &&
          (typeof artista.perfil === "object" &&
            artista.perfil.constructor === Object)
        ) {
          const cancionesPopulares = artista.songs.map(
            id => state.canciones.find(cancion => id === cancion.id).songtitle
          );

          const artistData = {
            nombre: artista.nombre,
            mainFoto:
              "/img/artistas/artistas-inside/" + artista.nombre + ".jpg",
            perfilFoto:
              "/img/artistas/avatar_artistas/" + artista.nombre + ".jpg",
            perfil: artista.perfil,
            cancionesPopulares: cancionesPopulares,
            index: index,
            id: artista.id
          };
          listadoArtistas.push(artistData);
          index++;

          if (
            artistData.nombre === "El Chacal" ||
            artistData.nombre === "Chocolate" ||
            artistData.nombre === "El Taiger" ||
            artistData.nombre === "El Dany" ||
            artistData.nombre === "Yomil"
          ) {
            topFivePosicion.push(artistData);
          }

          if (
            artistData.nombre === "Alexander" ||
            artistData.nombre === "El Chacal" ||
            artistData.nombre === "Randy Malcom" ||
            artistData.nombre === "Jacob Forever" ||
            artistData.nombre === "Osmani García"
          ) {
            topFiveRedes.push(artistData);
          }

          const graphArtist = state.datosGrafo.find(
            a => a.nombre === artistData.nombre
          );
          if (graphArtist) {
            graphArtist.mainFoto = artistData.mainFoto;
            graphArtist.perfilFoto = artistData.perfilFoto;
            graphArtist.perfil = artistData.perfil;
            graphArtist.cancionesPopulares = artistData.cancionesPopulares;
          }

          const priceArtist = state.datosPremios.find(
            a => a.nombre === artistData.nombre
          );
          if (priceArtist) {
            priceArtist.mainFoto = artistData.mainFoto;
            priceArtist.perfilFoto = artistData.perfilFoto;
            priceArtist.perfil = artistData.perfil;
            priceArtist.cancionesPopulares = artistData.cancionesPopulares;
          }
        }
      });

      commit("setListadoArtistas", {
        general: listadoArtistas,
        topFivePos: topFivePosicion,
        topFiveRedes
      });
    },
    GET_CANCIONES({ commit }) {
      return import(
        /* webpackChunkName: "data-songs" */ "./assets/songs.json"
      ).then(module => {
        commit("canciones", module.default);
      });
    },
    async fetchArtistasData({ dispatch, commit, state }) {
      const promises = [];
      if (!state.loadedArtistas) {
        promises.push(dispatch("GET_ARTISTAS"));
      }
      if (!state.loadedPosicion) {
        promises.push(dispatch("GET_DATOS_POSICION"));
      }
      if (!state.loadedRedes) {
        promises.push(dispatch("GET_DATOS_REDES"));
      }
      await Promise.all(promises);
      commit("setLoadedFullArtistas", true);
    },
    async fetchSongsYear({ dispatch, commit, state }) {
      commit("clearListaCanciones");
      if (!state.loadedSongs) {
        await dispatch("GET_CANCIONES");
      }
      for (let cancion of state.canciones) {
        if (cancion.year === state.songYear) {
          commit("addCancion", cancion);
        }
      }
    },
    async fetchTitulosPrincipales({ dispatch, commit, state }) {
      commit("clearTitulosPrincipales");
      await dispatch("fetchSongsYear");
      await dispatch("bestSongYear");
      for (let el of state.listaCanciones) {
        if (
          el.fact_description !== "" &&
          el.songtitle !== state.best_song_year
        ) {
          commit("addTituloPrincipal", {
            show_info: false,
            data: el
          });
        }
      }
    },
    async bestSongYear({ dispatch, commit, state }) {
      await dispatch("getSongOfYear");
      for (let el of state.listaCanciones) {
        if (el.songtitle === state.best_song_year) {
          commit("addTituloPrincipal", {
            show_info: true,
            data: el,
            bestSong: true
          });
        }
      }
    },
    async getSongOfYear({ commit, state }) {
      for (let el of state.best_songs) {
        if (el.year === state.songYear) {
          commit("setBestSongYear", el.name);
        }
      }
    },
    async fetchTitulosSecundarios({ dispatch, commit, state }) {
      commit("clearTitulosSecundarios");
      await dispatch("fetchSongsYear");
      for (let el of state.listaCanciones) {
        if (el.fact_description === "") {
          commit("addTituloSecundario", {
            show_info: false,
            data: el
          });
        }
      }
    },
    async spliceTitulosSecundarios({ dispatch, commit, state }) {
      commit("clearTitulosSecundariosPartitioned");
      await dispatch("fetchTitulosSecundarios");
      await dispatch("fetchTitulosPrincipales");
      let counter = 0,
        splice_range = Math.round(
          state.titulosSecundarios.length / state.titulosPrincipales.length
        );

      while (state.titulosSecundarios.length > 0) {
        if (state.titulosPrincipales.length - counter === 1)
          splice_range = state.titulosSecundarios.length;
        else if (
          state.titulosPrincipales.length - counter === 2 &&
          state.titulosSecundarios.length > splice_range
        )
          splice_range = Math.round(state.titulosSecundarios.length / 2);
        else if (
          splice_range === state.titulosSecundarios.length ||
          splice_range > state.titulosSecundarios.length
        )
          splice_range = Math.round(state.titulosSecundarios.length / 2);
        counter = counter + 1;
        commit(
          "addToTitulosSecundariosPartitioned",
          state.titulosSecundarios.splice(0, splice_range)
        );
      }
    },
    async getCurrentCamino({ dispatch, commit, state }) {
      for (let data of state.camino_data) {
        if (state.current_camino_id == data.id) {
          commit("setCurrentCaminoData", data);
        }
      }
      await dispatch("setCaminoData");
    },
    async setCaminoData({ commit, state }) {
      commit("setCurrentCaminoImg", state.current_camino_data.src);
      commit(
        "setCurrentCaminoText",
        state.current_camino_data.presentation_text
      );
      commit("setCurrentCaminoMainText", state.current_camino_data.main_text);
    }
  }
});
