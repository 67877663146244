import Vue from "vue";
import Router from "vue-router";
import Head from "vue-head";

// import Home from '@/views/Home/Home'
const Home = () =>
  import(/* webpackChunkName: "view-Home" */ "@/views/Home/Home");
// import Canciones from '@/views/Canciones/Canciones'
const Canciones = () =>
  import(
    /* webpackChunkName: "view-Canciones" */ "@/views/Canciones/Canciones"
  );
// import ListaCanciones from '@/views/Canciones/ListaCanciones'
const ListaCanciones = () =>
  import(
    /* webpackChunkName: "view-Canciones" */ "@/views/Canciones/ListaCanciones"
  );
// import YearDetails from './components/Canciones/YearDetails';
const YearDetails = () =>
  import(
    /* webpackChunkName: "view-Canciones" */ "./components/Canciones/YearDetails"
  );
// import ArtistasComponent from '@/views/Artistas/ArtistasComponent'
const ArtistasComponent = () =>
  import(
    /* webpackChunkName: "view-Artistas" */ "@/views/Artistas/ArtistasComponent"
  );
// import ArtistaInside from '@/views/Artistas/ArtistaInsideComponent'
// import Money from '@/views/Money/Money'
const Money = () =>
  import(/* webpackChunkName: "view-Money" */ "@/views/Money/Money");
// import Juego from '@/views/Juego/Juego'
const Juego = () =>
  import(/* webpackChunkName: "view-Juego" */ "@/views/Juego/Juego");

import goTo from "vuetify/lib/components/Vuetify/goTo";

Vue.use(Head);
Vue.use(Router);

export default new Router({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo, { duration: 0 });
  },
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
      name: "component.home",
      meta: {
        title: "Flow y poder - Reguetón en Cuba",
        description:
          "El Regueton cubano, que alardea de un flow contagioso y un músculo financiero impresionante, en los últimos ocho años ha generado conflictos, censuras, disputas, éxitos y estrellas."
      }
      // props: true,
    },
    {
      path: "/canciones-populares",
      component: Canciones,
      meta: {
        title: "Flow y poder - Canciones + Populares",
        description:
          "Recogemos de manera sintetizada los éxitos, controversias y momentos más significativos de este género urbano en Cuba en los últimos ocho años."
      },
      // props: true,
      children: [
        {
          path: "",
          name: "songs-home",
          component: YearDetails,
          meta: {
            title: "Flow y poder - Canciones + Populares",
            description:
              "Recogemos de manera sintetizada los éxitos, controversias y momentos más significativos de este género urbano en Cuba en los últimos ocho años."
          }
        },
        {
          path: ":current_year",
          name: "song-list",
          props: true,
          component: ListaCanciones,
          meta: {
            title: "Flow y poder - Canciones + Populares",
            description:
              "Recogemos de manera sintetizada los éxitos, controversias y momentos más significativos de este género urbano en Cuba en los últimos ocho años."
          }
        }
      ]
    },
    {
      path: "/la-realeza",
      name: "la.realeza",
      component: ArtistasComponent,
      // props: true,
      meta: {
        title: "Flow y poder - La Realeza",
        description:
          "¿Quiénes son los más populares? o ¿quiénes han estado más tiempo pegados? son preguntas habituales cuando se habla de reguetón cubano. A partir de una lista de 155 artistas y un análisis de varios indicadores empezamos a calcular... Aquí los resultados... y un ganador."
      }
    },
    {
      path: "/las-balas",
      name: "las.balas",
      component: Money,
      // props: true,
      meta: {
        title: "Flow y poder - Las Balas",
        description:
          "El reguetón generó una industria con artistas, productores musicales, realizadores de videos, distribuidores, canales para su consumo y circuitos de presentación. Y no solo en Cuba, también traspasó la frontera nacional. En la actualidad, parece consolidada y mueve millones de dólares anualmente."
      }
    },
    {
      path: "/el-cubeton",
      name: "el.cubeton",
      component: Juego,
      // props: true,
      meta: {
        title: "Flow y poder - El Cubetón",
        description: "Para que juegues a ritmo de reguetón cubano."
      }
    }
    // {
    //     path: '/artistas/:artista',
    //     name: 'artista-info',
    //     component: ArtistaInside,
    //     props: true
    // },
    // {
    //     path: '/canciones/:current_year/lista-canciones',
    //     name: 'lista-canciones',
    //     component: ListaCanciones,
    //     props: true
    // }
  ]
});
